import React from 'react';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { useReactiveVar } from '@apollo/client';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import * as Device from 'react-device-detect';

import { loginStatusVar } from '@context';
import Login from './Login';
import HeroSection from './HeroSection';
import Signup from './signup';

export interface ILoginDialogProps {
  open?: boolean;
  button?: React.ReactElement<ListItemButtonProps>;
}

export function AuthenticationDialog(props: ILoginDialogProps) {
  const theme = useTheme();
  const loginStatus = useReactiveVar(loginStatusVar);
  const { hasAccessToken, isSessionExpired, shouldLogFirst } = loginStatus;
  const [open, setOpen] = React.useState(false);
  const [showRegister, setShowRegister] = React.useState(false);
  const [loginVisible, setLoginVisible] = React.useState(true);
  const { isLandscape } = Device.useMobileOrientation();

  const isFullScreenDisplay = Device.isDesktop && !isSessionExpired;
  const isLandscapeMobile = Device.isMobileOnly && isLandscape;

  React.useEffect(() => {
    setOpen((isSessionExpired || shouldLogFirst) && !hasAccessToken);
  }, [hasAccessToken, isSessionExpired, shouldLogFirst]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClickClose = (event: React.MouseEvent, reason: string) => {
    if (isFullScreenDisplay && reason && reason === 'backdropClick') {
      return;
    }

    loginStatusVar({
      ...loginStatus,
      isSessionExpired: false,
      shouldLogFirst: false,
      redirection: null,
    });

    if (!isSessionExpired && !shouldLogFirst && !hasAccessToken) {
      setOpen(false);
    }

    setTimeout(() => {
      setShowRegister(false);
      setLoginVisible(true);
    }, 1000);
  };

  const handleToggleRegister = () => {
    if (!showRegister) {
      setShowRegister(true);
    } else {
      setLoginVisible(true);
      setShowRegister(false);
    }
  };

  const handleTransitionEnd = () => {
    if (showRegister) {
      setLoginVisible(false);
    }
  };

  return (
    <>
      {props.button && React.cloneElement(props.button, { onClick: handleClickOpen })}
      <Dialog
        fullScreen={isLandscapeMobile}
        fullWidth
        open={open}
        maxWidth={Device.isTablet || (Device.isMobileOnly && isLandscape) ? 'xl' : 'xs'}
        onClose={handleClickClose}
        PaperProps={{
          sx: {
            border: '1px solid rgba(255, 255, 255, 0.2)',
          },
        }}
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
        }}
      >
        {isFullScreenDisplay ? (
          <Stack direction="row" sx={{ height: '95vh', width: '100%', position: 'relative' }}>
            <IconButton
              onClick={handleClickClose}
              sx={{ position: 'absolute', top: 20, right: 20, zIndex: 2 }}
            >
              <theme.icons.close sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
            <Stack
              direction="row"
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
              }}
            >
              <Stack
                direction="row"
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  opacity: showRegister ? 0 : 1,
                  transition: 'opacity .5s ease',
                  visibility: loginVisible ? 'visible' : 'hidden',
                }}
                onTransitionEnd={handleTransitionEnd}
              >
                <Stack width="100%" justifyContent="center" alignItems="center" px={20}>
                  <Login />
                </Stack>
                <HeroSection handleToggleRegister={handleToggleRegister} />
              </Stack>

              <Stack
                direction="row"
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  opacity: showRegister ? 1 : 0,
                  transition: 'opacity .5s ease',
                  visibility: showRegister ? 'visible' : 'hidden',
                }}
              >
                <Signup handleToggleRegister={handleToggleRegister} />
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <Stack p={isLandscapeMobile ? 2 : 5} height="100%" sx={{ position: 'relative' }}>
            <IconButton onClick={handleClickClose} sx={{ position: 'absolute', top: 5, right: 5, zIndex: 2 }}>
              <theme.icons.close sx={{ color: theme.palette.text.secondary }} />
            </IconButton>
            <Login />
          </Stack>
        )}
      </Dialog>
    </>
  );
}
