import * as React from 'react';

import { useListHomeSpotsLazyQuery, ListHomeSpotsQuery } from '@graphql';
import { showBackDropVar } from '@context';
import { SlidersWrapper } from '@components';
import { SpotHighlight, SpotSliders } from './components';
import * as Device from 'react-device-detect';

const ONE_HOUR = 60 * 60 * 1000;
const SPOTS_LAST_CALL_KEY = 'SPOTS_LAST_CALL';

const SpotsPage = () => {
  const [visibleCarousels, setVisibleCarousels] = React.useState<number[]>([]);
  const [homeSpots, setHomeSpots] = React.useState<ListHomeSpotsQuery['homeSpots'] | null>(null);
  const { isLandscape } = Device.useMobileOrientation();

  const isMobileLandscape = Device.isMobileOnly && isLandscape;

  const getLastCallFromStorage = () => {
    const storedLastCall = localStorage.getItem(SPOTS_LAST_CALL_KEY);
    return storedLastCall ? parseInt(storedLastCall, 10) : new Date().getTime();
  };

  const [lastCall, setLastCall] = React.useState<number>(getLastCallFromStorage());

  const [getSpots, { loading }] = useListHomeSpotsLazyQuery({
    onCompleted: ({ homeSpots }) => {
      setHomeSpots(homeSpots ?? null);
      const now = new Date().getTime();
      setLastCall(now);
      localStorage.setItem(SPOTS_LAST_CALL_KEY, now.toString());
    },
  });

  React.useEffect(() => {
    const now = new Date().getTime();
    const timeSinceLastCall = now - lastCall;

    if (loading) {
      showBackDropVar(true);
    }

    if (!homeSpots || timeSinceLastCall > ONE_HOUR) {
      getSpots({
        fetchPolicy: timeSinceLastCall > ONE_HOUR ? 'cache-and-network' : 'cache-first',
      });
    }

    if (!loading && homeSpots) {
      showBackDropVar(false);
    }
  }, [homeSpots, lastCall]);

  const handleInView = (index: number) => {
    setVisibleCarousels((prev) => [...new Set([...prev, index, index + 1])]);
  };

  return (
    <>
      {homeSpots && (
        <>
          {!isMobileLandscape && <SpotHighlight spot={homeSpots[3].spots[1]} />}
          <SlidersWrapper>
            {homeSpots.map((spotsCategory, index) => (
              <SpotSliders
                key={spotsCategory.id}
                handleInView={handleInView}
                index={index}
                spotsCategory={spotsCategory}
                visibleCarousels={visibleCarousels}
              />
            ))}
          </SlidersWrapper>
        </>
      )}
    </>
  );
};

export default SpotsPage;
