import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import Skeleton from '@mui/material/Skeleton';
import { ListHomeSpotsQuery } from '@graphql';
import SpotSlider from './SpotSlider';

interface SpotSlidersProps {
  handleInView: (index: number) => void;
  index: number;
  spotsCategory: ListHomeSpotsQuery['homeSpots'][0];
  visibleCarousels: number[];
}

const spotSliders = (props: SpotSlidersProps) => {
  const { handleInView, index, spotsCategory, visibleCarousels } = props;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      handleInView(index);
    }
  }, [inView, index]);

  return (
    <div ref={ref}>
      {visibleCarousels.includes(index) ? (
        <SpotSlider title={spotsCategory.title} spots={spotsCategory.spots} />
      ) : (
        <Skeleton variant="rectangular" width="100%" height={300} />
      )}
    </div>
  );
};

export default spotSliders;
