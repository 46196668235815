import * as React from 'react';
import moment, { Moment } from 'moment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useGetSpoEventsQuery, GetSpoEventsQuery } from '@graphql';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Divider from '@mui/material/Divider';
import { useTranslation } from 'react-i18next';

import CalendarRenderDay from './CalendarRenderDay';
import DaySlot from './DaySlot';

interface AgendaProps {
  slug: string;
}

const Agenda = (props: AgendaProps) => {
  const { slug } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [hoveredDay, setHoveredDay] = React.useState<Moment | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<Moment | null>(moment().startOf('isoWeek'));
  const [weekEvents, setWeekEvents] = React.useState<GetSpoEventsQuery['spot']['events'] | []>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const showCalendar = Boolean(anchorEl);
  const { t } = useTranslation('cta');

  // Set the date range based on the selected date
  const dateRange = {
    from: selectedDate?.startOf('isoWeek').toISOString(),
    to: selectedDate?.endOf('isoWeek').toISOString(),
  };

  const { data, loading } = useGetSpoEventsQuery({
    variables: { slug, dateRange },
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    if (data && data.spot && data.spot.events) {
      setWeekEvents(data.spot.events);
    }
  }, [data]);

  const handleClickAgenda = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseAgenda = () => {
    setAnchorEl(null);
  };

  const getEventsForDay = (day: string) => {
    const dayDate = selectedDate?.clone().isoWeekday(day);

    return weekEvents.filter((event) => {
      return moment(event.from).isSame(dayDate, 'day');
    });
  };

  const handleGoToCurrentWeek = () => {
    setSelectedDate(moment().startOf('isoWeek'));
  };

  const handlePreviousWeek = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedDate((prev) => prev?.clone().subtract(1, 'week') || moment());
  };

  const handleNextWeek = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setSelectedDate((prev) => prev?.clone().add(1, 'week') || moment());
  };

  const weekNumber = selectedDate?.isoWeek() || moment().isoWeek();
  const weekStart = selectedDate?.startOf('isoWeek').format('DD MMM') || '';
  const weekEnd = selectedDate?.endOf('isoWeek').format('DD MMM YYYY') || '';

  return (
    <Stack spacing={isMobile ? 3 : 6} p={3}>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent={isMobile ? 'flex-start' : 'space-between'}
        alignItems={isMobile ? 'flex-start' : 'center'}
        spacing={2}
      >
        <Typography variant="h4">Agenda</Typography>
        <Stack direction="row" spacing={2} width="100%" justifyContent="space-between">
          <>
            <Button
              variant="contained"
              color="uncolored"
              size={isMobile ? 'small' : 'medium'}
              id="basic-button"
              aria-controls={showCalendar ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={showCalendar ? 'true' : undefined}
              onClick={handleClickAgenda}
              startIcon={<theme.icons.calendar />}
            >
              {t('calendar')}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={showCalendar}
              onClose={handleCloseAgenda}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <DateCalendar
                value={selectedDate}
                onChange={(newValue) => {
                  setAnchorEl(null);
                  if (newValue) {
                    // Ensure to start at the beginning of the week
                    setSelectedDate(newValue.startOf('isoWeek'));
                  }
                }}
                showDaysOutsideCurrentMonth
                displayWeekNumber
                slots={{ day: CalendarRenderDay }}
                slotProps={{
                  day: (ownerState) => ({
                    selectedDay: selectedDate,
                    hoveredDay,
                    onPointerEnter: () => setHoveredDay(ownerState.day),
                    onPointerLeave: () => setHoveredDay(null),
                  }),
                }}
              />
            </Menu>
          </>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? 'small' : 'medium'}
            onClick={handleGoToCurrentWeek}
            startIcon={<theme.icons.week />}
            sx={{ alignSelf: isMobile ? 'flex-end' : 'inherit' }}
          >
            {t('this week')}
          </Button>
        </Stack>
      </Stack>

      <Paper
        elevation={3}
        sx={{
          p: { sm: 1, md: 2 },
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {isMobile ? (
            <IconButton variant="contained" color="uncolored" onClick={handlePreviousWeek}>
              <theme.icons.back />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              color="uncolored"
              startIcon={<theme.icons.back />}
              onClick={handlePreviousWeek}
            >
              {t('previous week')}
            </Button>
          )}
          <Typography variant="body2" fontWeight="bold" fontSize={{ sm: '1rem', md: '1.2rem' }}>
            S#{weekNumber} | du {weekStart} au {weekEnd}
          </Typography>
          {isMobile ? (
            <IconButton variant="contained" color="uncolored" onClick={handleNextWeek}>
              <theme.icons.chevron />
            </IconButton>
          ) : (
            <Button
              variant="contained"
              color="uncolored"
              endIcon={<theme.icons.chevron />}
              onClick={handleNextWeek}
            >
              {t('next week')}
            </Button>
          )}
        </Stack>
      </Paper>

      <Stack
        spacing={isMobile ? 1 : 3}
        divider={<Divider sx={{ backgroundColor: theme.palette.grey[800] }} />}
      >
        <DaySlot
          day="Lundi"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Lundi')}
        />
        <DaySlot
          day="Mardi"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Mardi')}
        />
        <DaySlot
          day="Mercredi"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Mercredi')}
        />
        <DaySlot
          day="Jeudi"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Jeudi')}
        />
        <DaySlot
          day="Vendredi"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Vendredi')}
        />
        <DaySlot
          day="Samedi"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Samedi')}
        />
        <DaySlot
          day="Dimanche"
          loading={loading}
          selectedDate={selectedDate}
          events={getEventsForDay('Dimanche')}
        />
      </Stack>
    </Stack>
  );
};

export default Agenda;
