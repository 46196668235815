import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';

import { ListHomeEventsQuery } from '@graphql';
import useStyles from './styles';

interface EventHighlightProps {
  event: ListHomeEventsQuery['eventCategories'][0]['events'][0];
}

const EventHighlight = (props: EventHighlightProps) => {
  const { event } = props;
  const theme = useTheme();
  const styles = useStyles(theme, event?.covers[0]?.url || '');
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();

  const handleOpen = () => {
    setSearchParams({ type: 'event', q: event.id }, { preventScrollReset: true });
  };

  const TopTen = () => {
    const size = isScreenMedium ? theme.spacing(6) : theme.spacing(10);

    return (
      <Stack
        width={size}
        height={size}
        alignItems="center"
        justifyContent="center"
        borderRadius={theme.spacing(1)}
        sx={{
          zIndex: 1,
          boxShadow: `1px 1px 5px ${alpha('#000', 0.8)}`,
          backgroundColor: alpha(theme.palette.primary.main, 0.9),
        }}
      >
        <Typography fontSize={isScreenMedium ? '.5rem' : '1rem'} fontWeight={800}>
          Top
        </Typography>
        <Typography fontSize={isScreenMedium ? '.8rem' : '1.5rem'} fontWeight={800}>
          10
        </Typography>
      </Stack>
    );
  };

  return (
    <Stack justifyContent="center" px={{ xs: 2, sm: 5, md: 10 }} sx={styles.highlight}>
      <Stack width="100%" spacing={{ xs: 2, md: 5 }}>
        <Typography variant="h3" fontWeight={800} sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}>
          {event.title}
        </Typography>
        <Stack direction="row" alignItems={'center'}>
          <TopTen />
          <Stack
            p={theme.spacing(1, 2)}
            borderRadius={theme.spacing(0, 1, 1, 0)}
            sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.9) }}
          >
            <Typography component="h2" fontWeight={800}>
              N°7 des évènements
            </Typography>
            <Typography component="h3" fontWeight={800}>
              {event.category?.name || null}
            </Typography>
          </Stack>
        </Stack>
        <Typography
          width={isScreenMedium ? '100%' : '50%'}
          fontSize="1.1rem"
          fontWeight="bold"
          sx={{ color: 'white', textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
        >
          {event.description?.split(' ').slice(0, 20).join(' ')}...
        </Typography>
        <Box width={isScreenMedium ? '100%' : '50%'}>
          <Button variant="contained" startIcon={<theme.icons.see />} onClick={handleOpen}>
            Voir l'event
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default EventHighlight;
