import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import moment from 'moment';

import { useConnection } from '@hooks';
import { useSignupMutation, GenderEnum, AccountTypeEnum } from 'graphql';
import initValues from './initValues';
import validationSchema from './validationSchemas';
import FormFields from './FormFields';
import Stepper from './Stepper';
import { getSteps } from './helpers';

interface SignupFormProps {
  type: 'consumer' | 'spot' | 'artist';
  handleBack: () => void;
}

export default function SignupForm(props: SignupFormProps) {
  const { handleBack, type } = props;
  const theme = useTheme();
  const { login } = useConnection();
  const [isSignupSuccess, setIsSignupSuccess] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
  const { t } = useTranslation('field');

  const { steps, stepsCaptions, stepFieldValidations } = getSteps(type);

  const [signup] = useSignupMutation();

  const formik = useFormik({
    initialValues: initValues(type),
    validationSchema: validationSchema(type, t),
    onSubmit: async (values) => {
      const validValues = {
        isSSO: false,
        type: type.toUpperCase() as AccountTypeEnum,
        email: values.email,
        password: values.password,
        firstname: values.firstname,
        lastname: values.lastname,
        mobilePhone: values.mobilePhone,
        gender: values.gender.toUpperCase() as GenderEnum,
        birthdate: moment(values.birthdate).toISOString(),
        siret: values.siret,
      };

      signup({
        variables: { input: validValues },
        onCompleted() {
          setIsSignupSuccess(true);
        },
        onError(error) {
          // @ts-expect-error networkError is not a property of ServerError
          const networkError = error?.networkError?.result?.errors?.[0]?.message;

          if (networkError === 'EMAIL_ALREADY_REGISTERED') {
            formik.setErrors({ email: 'Email déjà enregistré' });
          } else if (networkError === 'SIRET_ALREADY_REGISTRED') {
            formik.setErrors({ siret: 'Établissemnt déjà enregistré' });
          }
        },
      });
    },
  });

  React.useEffect(() => {
    if (activeStep !== steps.length) {
      // avoid checking the last step (summary)
      const isStepFieldValid = stepFieldValidations[activeStep].every(
        (field) => !formik.errors[field as keyof typeof formik.errors]
      );

      setCompleted({ ...completed, [activeStep]: isStepFieldValid });
    }
  }, [activeStep, formik.values, formik.errors]);

  const handleNext = () => {
    if (activeStep < steps.length) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBackStep = () => {
    setActiveStep(activeStep - 1);
  };

  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const handleLogin = () => {
    login({
      variables: {
        input: {
          email: formik.values.email,
          password: formik.values.password,
        },
      },
    });
    formik.resetForm({ values: initValues(type) });
  };

  return !isSignupSuccess ? (
    <Stack
      width="100%"
      height="100%"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      p={theme.spacing(0, 10)}
    >
      <Button
        onClick={handleBack}
        type="button"
        variant="outlined"
        color="white"
        startIcon={<theme.icons.back />}
        sx={{ borderRadius: '100px', position: 'absolute', top: 40, left: 40, zIndex: 2 }}
      >
        Retour au choix de compte
      </Button>
      <form onSubmit={formik.handleSubmit} style={{ width: '100%', height: '100%', marginTop: 50 }}>
        <Stack height="100%" justifyContent="space-between" alignItems="center">
          {activeStep < steps.length && (
            <Stepper
              steps={steps}
              stepsCaptions={stepsCaptions}
              activeStep={activeStep}
              completed={completed}
              handleStep={handleStep}
            />
          )}
          <FormFields type={type} activeStep={activeStep} formik={formik} />
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Button
              variant="contained"
              color="inherit"
              type="button"
              disabled={activeStep === 0}
              onClick={handleBackStep}
              sx={{ display: activeStep === 0 ? 'none' : 'block' }}
            >
              Retour
            </Button>
            {activeStep < steps.length ? (
              <Button
                variant="contained"
                type="button"
                onClick={handleNext}
                disabled={activeStep !== steps.length ? !completed[activeStep] : !formik.isValid}
                color="success"
                sx={{ ml: 'auto' }}
                startIcon={activeStep === steps.length - 1 ? <theme.icons.summary /> : <theme.icons.check />}
              >
                {activeStep === steps.length - 1 ? 'Récapitulatif' : 'Valider'}
              </Button>
            ) : (
              <Button
                variant="contained"
                type="button"
                onClick={() => {
                  if (activeStep === steps.length) {
                    formik.handleSubmit();
                  }
                }}
                disabled={!formik.isValid}
                color="success"
                sx={{ ml: 'auto' }}
                startIcon={<theme.icons.rocket />}
              >
                C'est parti
              </Button>
            )}
          </Stack>
        </Stack>
      </form>
    </Stack>
  ) : (
    <Stack width="100%" height="100%" justifyContent="center" alignItems="center" spacing={2}>
      <Stack spacing={3} justifyContent="center" alignItems="center">
        <Typography variant="h3">Félicitations {formik.values.firstname} 🎉</Typography>
        <Typography>Ton compte a bien été créé. Un email a été envoyé à </Typography>
        <Alert icon={<theme.icons.check fontSize="inherit" />} severity="success">
          {formik.values.email}
        </Alert>
        <Typography>Tu devrais pas tarder à le recevoir, il te servira à valider ton compte.</Typography>
        <Button variant="contained" onClick={handleLogin}>
          Fermer et me connecter
        </Button>
      </Stack>
    </Stack>
  );
}
