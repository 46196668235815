import * as React from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useConnection } from '@hooks';
import { Logo } from '@components';
import AccountMenu from '../../components/header/account/menu/AccountMenu';
import DrawerHeader from './DrawerHeader';
import useStyles from './styles';

interface DrawerProps {
  open: boolean;
  // handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
}

const drawerWidth = 300;

export default function AppDrawer(props: DrawerProps) {
  const { open, handleDrawerClose } = props;
  const theme = useTheme();
  const { logout } = useConnection();
  const { t } = useTranslation('account');
  const styles = useStyles(theme, open);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <Stack width="100%" height="100%" alignItems="center" justifyContent="center">
          <Logo />
        </Stack>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon color="uncolored" />
          ) : (
            <ChevronLeftIcon color="uncolored" />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider sx={{ backgroundColor: theme.palette.background.default }} />
      {/* <AccountMenu /> */}
      <List sx={styles.listItem}>
        <ListItem disablePadding sx={{ display: 'block' }} component={Link} to="/spot/dashboard">
          <ListItemButton sx={styles.listItemButton}>
            <ListItemIcon sx={styles.listItemIcon}>
              <theme.icons.dashboard />
            </ListItemIcon>
            <ListItemText primary="Tableau de bord" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" />
      <List sx={styles.listItem}>
        <ListItem disablePadding sx={{ display: 'block' }} component={Link} to="/me/booking/calendar">
          <ListItemButton sx={styles.listItemButton}>
            <ListItemIcon sx={styles.listItemIcon}>
              <theme.icons.booking />
            </ListItemIcon>
            <ListItemText primary="Bookings" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: 'block' }} component={Link} to="/me/event/calendar">
          <ListItemButton sx={styles.listItemButton}>
            <ListItemIcon sx={styles.listItemIcon}>
              <theme.icons.event />
            </ListItemIcon>
            <ListItemText primary="Events" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" />
      <List sx={styles.listItem}>
        <ListItem disablePadding sx={{ display: 'block' }}>
          <ListItemButton sx={styles.listItemButton}>
            <ListItemIcon sx={styles.listItemIcon}>
              <theme.icons.artist />
            </ListItemIcon>
            <ListItemText primary="Artists" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" />
      <List sx={styles.listItem}>
        <ListItemButton sx={styles.listItemButton}>
          <ListItemIcon sx={styles.listItemIcon}>
            <theme.icons.settings />
          </ListItemIcon>
          <ListItemText primary={t('menu.account settings')} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>
      <Divider sx={{ backgroundColor: theme.palette.grey[800] }} variant="middle" />
      <List sx={styles.listItem}>
        <ListItemButton sx={styles.listItemButton} onClick={logout}>
          <ListItemIcon sx={styles.listItemIcon}>
            <theme.icons.logout />
          </ListItemIcon>
          <ListItemText primary={t('menu.account settings')} sx={{ opacity: open ? 1 : 0 }} />
        </ListItemButton>
      </List>
    </Drawer>
  );
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));
