import * as React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSession } from '@hooks';

import MeHeader from './MeHeader';

const ROUTE_PREFIX = '/me';

export default function MePage() {
  const { account } = useSession();
  const role = account?.__typename ?? 'Guest';
  const navigate = useNavigate();
  const location = useLocation();

  const authorizedPahtsByRole = {
    Guest: [],
    Consumer: ['calendar', 'table'],
    Maker: ['calendar', 'table'],
    Spot: [
      'booking/calendar',
      'booking/table',
      'booking/stats',
      'event/calendar',
      'event/table',
      'event/stats',
    ],
  };
  React.useEffect(() => {
    const [basePath = '/'] = authorizedPahtsByRole[role];

    if (location.pathname === ROUTE_PREFIX) {
      navigate(`${ROUTE_PREFIX}/${basePath}`, { replace: true });
    } else {
      // Get the authorized paths for the user's role
      const authorizedPaths = authorizedPahtsByRole[role]?.map((path) => `${ROUTE_PREFIX}/${path}`) || [];

      // Verify if the current route is authorized
      const isAuthorized = authorizedPaths.some((path) => location.pathname.startsWith(path));

      // Redirection if the user is not on an authorized route
      if (!isAuthorized) {
        navigate(basePath, { replace: true });
      }
    }
  }, [role, location, navigate]);

  return <MeHeader />;
}
