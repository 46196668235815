import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import Plans from './Plans';
import SignupForm from './signup-form';

interface SignupProps {
  handleToggleRegister: () => void;
}

export default function Signup(props: SignupProps) {
  const { handleToggleRegister } = props;
  const theme = useTheme();
  const [type, setType] = React.useState<'consumer' | 'spot' | 'artist' | null>(null);

  const handleChooseType = (type: 'consumer' | 'spot' | 'artist') => {
    setType(type);
  };

  const handleBack = () => {
    setType(null);
  };

  return (
    <Stack
      width="100%"
      height="100%"
      spacing={1}
      justifyContent="center"
      alignItems="center"
      p={theme.spacing(15, 10)}
    >
      {type === null && (
        <Button
          onClick={handleToggleRegister}
          type="button"
          variant="outlined"
          color="white"
          startIcon={<theme.icons.back />}
          sx={{ borderRadius: '100px', position: 'absolute', top: 40, left: 40, zIndex: 2 }}
        >
          Retour à la connexion
        </Button>
      )}
      <Stack width="100%" height={type === null ? 'auto' : '100%'}>
        {type === null ? (
          <Plans handleChooseType={handleChooseType} />
        ) : (
          <SignupForm type={type} handleBack={handleBack} />
        )}
      </Stack>
    </Stack>
  );
}
