import * as React from 'react';

import { useListHomeEventsLazyQuery, ListHomeEventsQuery } from '@graphql';
import { showBackDropVar } from '@context';
import { SlidersWrapper } from '@components';
import { EventSliders, EventHighlight } from './components';
import * as Device from 'react-device-detect';

const ONE_HOUR = 60 * 60 * 1000;
const EVENTS_LAST_CALL_KEY = 'EVENTS_LAST_CALL';

export default function EventsPage() {
  const [visibleCarousels, setVisibleCarousels] = React.useState<number[]>([]);
  const [events, setEvents] = React.useState<ListHomeEventsQuery['eventCategories'] | null>(null);
  const { isLandscape } = Device.useMobileOrientation();

  const isMobileLandscape = Device.isMobileOnly && isLandscape;

  const getLastCallFromStorage = () => {
    const storedLastCall = localStorage.getItem(EVENTS_LAST_CALL_KEY);
    return storedLastCall ? parseInt(storedLastCall, 10) : new Date().getTime();
  };

  const [lastCall, setLastCall] = React.useState<number>(getLastCallFromStorage());

  const [getEvents, { loading }] = useListHomeEventsLazyQuery({
    onCompleted: ({ eventCategories }) => {
      setEvents(eventCategories ?? null);
      const now = new Date().getTime();
      setLastCall(now);
      localStorage.setItem(EVENTS_LAST_CALL_KEY, now.toString());
    },
  });

  React.useEffect(() => {
    const now = new Date().getTime();
    const timeSinceLastCall = now - lastCall;

    if (loading) {
      showBackDropVar(true);
    }

    if (!events || timeSinceLastCall > ONE_HOUR) {
      getEvents({
        fetchPolicy: timeSinceLastCall > ONE_HOUR ? 'network-only' : 'cache-first',
      });
    }

    if (!loading && events) {
      showBackDropVar(false);
    }
  }, [events, lastCall]);

  const handleInView = (index: number) => {
    setVisibleCarousels((prev) => [...new Set([...prev, index, index + 1])]);
  };

  return (
    <>
      {events && (
        <>
          {!isMobileLandscape && <EventHighlight event={events[3].events[0]} />}
          <SlidersWrapper>
            {events.map((category, index) => (
              <EventSliders
                key={category.id}
                handleInView={handleInView}
                index={index}
                category={category}
                visibleCarousels={visibleCarousels}
              />
            ))}
          </SlidersWrapper>
        </>
      )}
    </>
  );
}
