import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';

import { ListHomeSpotsQuery } from '@graphql';
import { SliderWrapper, MobileSlide } from '@components';
import { getTrueKeys } from '@utils';
import SpotSlide from './SpotSlide';

interface SpotSliderProps {
  spots: ListHomeSpotsQuery['homeSpots'][0]['spots'];
  title: string;
}

const SpotSlider = (props: SpotSliderProps) => {
  const { title, spots } = props;
  const { t } = useTranslation('spot');

  return (
    <Stack width="100%" justifyContent="space-between">
      <Typography pl={{ xs: 2, sm: 3, md: 10 }} variant="h5" fontWeight={'bold'}>
        {title}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            margin: '-150px 0',
          }}
        >
          <SliderWrapper>
            {spots.map((spot) =>
              Device.isDesktop ? (
                <SpotSlide key={spot.id} spot={spot} />
              ) : (
                <MobileSlide
                  key={spot.id}
                  type="Spot"
                  to={spot.slug}
                  title={spot.name}
                  covers={spot.covers.map(({ id, url }) => ({
                    id,
                    url,
                  }))}
                  subtitleLeft={getTrueKeys(spot.type)
                    .map((type) => t(`type.${type}`))
                    .join(', ')}
                  subtitleRight={spot.address.city}
                />
              )
            )}
          </SliderWrapper>
        </Box>
      </Box>
    </Stack>
  );
};

export default SpotSlider;
