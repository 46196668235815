import * as yup from 'yup';
import moment from 'moment';
import { TFunction } from 'i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { GenderEnum } from '@graphql';

export default function getValidationSchema(type: 'consumer' | 'spot' | 'artist', t: TFunction) {
  const email = yup.string().email().required();
  const password = yup.string().min(8).required().label('Mot de passe');
  const passwordConfirmation = yup
    .string()
    .oneOf([yup.ref('password')])
    .required()
    .label('Confirmation du passe')
  const gender = yup.string().oneOf(Object.values(GenderEnum)).required();
  const firstname = yup.string().min(2).max(255).required().label('Prénom');
  const lastname = yup.string().min(2).max(255).required().label('Nom de famille');
  const birthdate = yup
    .date()
    .min(moment().subtract(130, 'years').toDate())
    .max(moment().subtract(10, 'years').toDate())
    .required('Birthdate is required')
    .label('Date de naissance');
  const accountName = yup.string().min(2).max(255).label('Nom du compte');
  const siret = yup.string().length(14).required().label('SIRET');
  const artistType = yup.string().oneOf(["dj", "comedian", "singer", "musician"]).required();
  const mobilePhone = yup
    .string()
    .required()
    .test('is-valid-mobile-phone', 'Invalid mobile phone number', (value) => {
      if (!value) return false;
      const phoneNumber = parsePhoneNumberFromString(value, 'FR');
      const isMobile = /^(\+33|0)[67]\d{8}$/.test(phoneNumber?.number || '');
      return phoneNumber?.isValid() && isMobile;
    })
    .label('Numéro de téléphone mobile');

  const commonFields = {
    email,
    password,
    passwordConfirmation,
    firstname,
    lastname,
    gender,
    birthdate,
  }

  switch (type) {
    case 'consumer':
      return yup.object({ ...commonFields })
    case 'spot':
      return yup.object({
        accountName,
        siret,
        mobilePhone,
        ...commonFields
      })
    case 'artist':
      return yup.object({
        accountName,
        artistType,
        ...commonFields
      })
    default:
      throw new Error('Invalid type')
  }
}