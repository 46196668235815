import { Theme } from '@mui/material';

const useStyles = (theme: Theme, open: boolean, isDrawer = false) => ({
  main: { width: '100vh', maxWidth: '-webkit-fill-available', minHeight: '100vh', flexGrow: 1, p: isDrawer ? 3 : null },
  listItem: {
    width: 300,
    color: theme.palette.text.secondary,
    '& .MuiListItemButton-root': {
      color: theme.palette.text.secondary,
      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: theme.palette.text.secondary,
      },
      '&:hover': {
        color: theme.palette.text.primary,
        '& .MuiListItemIcon-root, & .MuiListItemText-root': {
          color: theme.palette.text.primary,
        },
      },
    },
    '& .MuiDivider-root': {
      backgroundColor: theme.palette.grey[700],
    },
  },
  listItemButton: {
    minHeight: 48,
    justifyContent: open ? 'initial' : 'center',
    px: 2.5,
  },
  listItemIcon: {
    minWidth: 0,
    mr: open ? 3 : 'auto',
    justifyContent: 'center',
  },
  burgerMenu: {
    marginRight: 5,
    ...(open && { display: 'none' }),
  },
});

export default useStyles;
