import * as React from 'react';
import { useInView } from 'react-intersection-observer';
import Skeleton from '@mui/material/Skeleton';
import { ListHomeEventsQuery } from '@graphql';
import EventSlider from './EventSlider';

interface EventSlidersProps {
  handleInView: (index: number) => void;
  index: number;
  category: ListHomeEventsQuery['eventCategories'][0];
  visibleCarousels: number[];
}

const EventSliders = (props: EventSlidersProps) => {
  const { handleInView, index, category, visibleCarousels } = props;

  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  React.useEffect(() => {
    if (inView) {
      handleInView(index);
    }
  }, [inView, index]);

  return (
    <div ref={ref}>
      {visibleCarousels.includes(index) ? (
        <EventSlider title={category.name} events={category.events} />
      ) : (
        <Skeleton variant="rectangular" width="100%" height={300} />
      )}
    </div>
  );
};

export default EventSliders;
