import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';

interface HeroSectionProps {
  handleToggleRegister: () => void;
}

export default function HeroSection(props: HeroSectionProps) {
  const { handleToggleRegister } = props;
  const theme = useTheme();
  const { t } = useTranslation('authentication');

  const textColor = theme.palette.grey[800];
  const cardSx = {
    mb: 3,
    backgroundColor: 'white',
    borderRadius: '10px',
    boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
  };

  return (
    <Stack
      width="55%"
      spacing={1}
      justifyContent="space-evenly"
      sx={{ backgroundColor: theme.palette.primary.main }}
    >
      <Stack spacing={2} m={theme.spacing(0, 10)}>
        <Typography variant="h3" component="h1" color="white" textAlign="center">
          Events | Spots | Artists
        </Typography>
        <Typography variant="h5" component="h2" color="white" textAlign="center">
          Reste connecté•e au monde qui t'entoure
        </Typography>
      </Stack>
      <Stack width="100%" height="50%" justifyContent="center" alignItems="center">
        <Swiper
          className="mySwiper"
          effect={'cards'}
          grabCursor={true}
          modules={[EffectCards]}
          style={{ width: '70%' }}
          cardsEffect={{
            slideShadows: false,
          }}
        >
          <SwiperSlide>
            <Stack spacing={3} m={theme.spacing(0, 5)} p={3} sx={cardSx}>
              <img
                src="https://plus.unsplash.com/premium_photo-1681830630610-9f26c9729b75?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                style={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
              />
              <Typography variant="h5" component="h6" color={textColor} fontWeight="bold">
                Ne manque plus aucun <span style={{ color: theme.palette.primary.main }}>Event</span> près de
                chez toi !
              </Typography>
              <Typography variant="body1" color={textColor} fontWeight="bold">
                Découvre facilement les concerts, festivals et autres sorties près de toi grâce à notre
                application. Inscris-toi dès maintenant pour être toujours au courant et ne plus laisser
                passer les meilleures occasions !
              </Typography>
            </Stack>
          </SwiperSlide>

          <SwiperSlide>
            <Stack spacing={3} m={theme.spacing(0, 5)} p={3} sx={cardSx}>
              <img
                src="https://plus.unsplash.com/premium_photo-1681841594224-ad729a249113?q=80&w=3444&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                style={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
              />
              <Typography variant="h5" component="h6" color={textColor} fontWeight="bold">
                Réserve facilement tes <span style={{ color: theme.palette.primary.main }}>Spots</span>{' '}
                préférés et découvre-en de nouveaux
              </Typography>
              <Typography variant="body1" color={textColor} fontWeight="bold">
                De nouveaux lieux à découvrir en permanence, des endroits où se retrouver, des spots pour se
                détendre, pour faire la fête ou encore bien d'autres occasions.
              </Typography>
            </Stack>
          </SwiperSlide>

          <SwiperSlide>
            <Stack spacing={3} m={theme.spacing(0, 5)} p={3} sx={cardSx}>
              <img
                src="https://images.unsplash.com/photo-1530649159659-c8beb2992433?q=80&w=3570&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                style={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
              />
              <Typography variant="h5" component="h6" color={textColor} fontWeight="bold">
                Des <span style={{ color: theme.palette.primary.main }}>Artists</span> talentueux à découvrir
                en ligne et en représentation !
              </Typography>
              <Typography variant="body1" color={textColor} fontWeight="bold">
                Ne rate plus les dates de tes artistes préférés. Tien toi informé•e de leurs évènements dès
                qu'ils les publient ! Et partage tes découvertes avec tes amis.
              </Typography>
            </Stack>
          </SwiperSlide>

          <SwiperSlide>
            <Stack spacing={3} m={theme.spacing(0, 5)} p={3} sx={cardSx}>
              <img
                src="https://images.unsplash.com/photo-1554260570-47e791ab2fc7?q=80&w=3571&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                style={{ height: '200px', objectFit: 'cover', borderRadius: '10px' }}
              />
              <Typography variant="h5" component="h6" color={textColor} fontWeight="bold">
                Tu as un <span style={{ color: theme.palette.primary.main }}>Spot</span> à référencer ou tu es
                un <span style={{ color: theme.palette.primary.main }}>Artist</span> à promouvoir ?
              </Typography>
              <Typography variant="body1" color={textColor} fontWeight="bold">
                Inscris-toi dès maintenant pour accéder à ton espace personnel pour gérer tes évènements et
                tes réservations. Reste connecté avec ta communauté et partage tes actualités.
              </Typography>
            </Stack>
          </SwiperSlide>
        </Swiper>
      </Stack>
      <Stack p={theme.spacing(0, 10)}>
        <Button
          onClick={handleToggleRegister}
          variant="outlined"
          color="white"
          startIcon={<theme.icons.signup />}
          sx={{ borderRadius: '100px' }}
        >
          Je m'inscris
        </Button>
      </Stack>
    </Stack>
  );
}
