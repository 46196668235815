export * from "./constants";
export * from "./image";

import { GetConversationsQuery, GetConversationQuery } from '@graphql';

export function debounce(fn: (...args: any[]) => any, delay: number) {
  let timeoutId: ReturnType<typeof setTimeout>;

  return (...args: any[]): Promise<any> => {
    return new Promise((resolve) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        const result = await fn(...args);
        resolve(result);
      }, delay);
    });
  };
}

export function truncateText(text: string, maxLength: number = 40): string {
  if (text.length > maxLength) {
    return text.slice(0, maxLength).trim() + '...';
  }
  return text;
}

export function getConversationTitle(
  accountId: string,
  conversation: GetConversationsQuery['conversations'][0] | GetConversationQuery['conversation'],
  truncateTextLength = 25
) {
  let name = '';

  if (conversation.name) {
    name = conversation.name;
  } else {
    name =
      conversation.members.length > 2
        ? conversation.members
            .filter((m) => m.id !== accountId)
            .map((m) => m.name)
            .join(', ')
        : conversation.members.find((m) => m.id !== accountId)?.name || '';
  }

  return truncateText(name, truncateTextLength);
}

export function formatPriceRange(averageTicket?: number | null) {
  if (!averageTicket) {
    return 'NC';
  } else if (averageTicket < 20) {
    return 'cheap';
  } else if (averageTicket < 50) {
    return 'accessible';
  } else if (averageTicket < 200) {
    return 'high';
  } else {
    return 'premium';
  }
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getTrueKeys(obj: Record<string, boolean | string>): string[] {
  return Object.keys(obj)
    .filter((key) => obj[key] === true)
    .filter((_, idx) => idx < 3)
}