import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import * as Device from 'react-device-detect';

import { useSession } from '@hooks';
import { Logo, AccountHeader, AuthenticationDialog } from '@components';
import ConsumerHeader from './ConsumerHeader';
import AppBar from './AppBar';
import BurgerMenu from './BurgerMenu';
import Drawer from './Drawer';
import DrawerHeader from './DrawerHeader';
import useStyles from './styles';

export default function DesktopApp() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { account, isLoggedIn } = useSession();
  const [isDrawer, setIsDrawer] = React.useState(false);
  const appBarRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState<number | undefined>(undefined);
  const styles = useStyles(theme, open, isDrawer);
  const { t } = useTranslation('account');

  React.useEffect(() => {
    setIsDrawer(['Spot', 'Maker'].includes(account?.__typename ?? ''));
  }, [account?.__typename]);

  React.useEffect(() => {
    if (appBarRef.current) {
      const measuredHeight = appBarRef.current.offsetHeight;
      setHeight(measuredHeight);
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar ref={appBarRef} position="fixed" open={open}>
        <Toolbar>
          {isDrawer || (Device.isMobileOnly && isLoggedIn) ? (
            <BurgerMenu open={open} handleDrawerOpen={handleDrawerOpen} />
          ) : Device.isMobileOnly ? (
            <Stack height={`${height && height - 20}px`}>
              <Logo variant="letter" />
            </Stack>
          ) : (
            <Logo variant="full" size="large" />
          )}

          <Stack width="100%" direction="row" justifyContent="space-between" alignItems="center">
            <Box sx={{ ml: isDrawer ? 0 : 3 }}>
              {isDrawer ? (
                <Typography variant="h6" noWrap component="div" sx={{ cursor: 'default' }}>
                  {!isLoggedIn || !account ? null : account.name}
                </Typography>
              ) : (
                <ConsumerHeader />
              )}
            </Box>
            {isLoggedIn ? (
              <AccountHeader />
            ) : (
              <AuthenticationDialog
                button={
                  <Button variant="contained" size="small">
                    {t('connection', { ns: 'cta' })}
                  </Button>
                }
              />
            )}
          </Stack>
        </Toolbar>
      </AppBar>
      {(isDrawer || (Device.isMobileOnly && isLoggedIn)) && (
        <Drawer open={open} handleDrawerClose={handleDrawerClose} />
      )}
      <Box component="main" sx={styles.main}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
