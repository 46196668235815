import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import useStyles from './styles';

interface BurgerMenuProps {
  handleDrawerOpen: () => void;
  open: boolean;
}

export default function BurgerMenu(props: BurgerMenuProps) {
  const { open, handleDrawerOpen } = props;
  const theme = useTheme();
  const styles = useStyles(theme, open);

  return (
    <IconButton
      color="uncolored"
      aria-label="open drawer"
      onClick={handleDrawerOpen}
      edge="start"
      sx={styles.burgerMenu}
    >
      <MenuIcon sx={{ color: theme.palette.text.secondary }} />
    </IconButton>
  );
}
