import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { useSession } from '@hooks';
import { GetSpotDetailedQuery } from '@graphql';
import FollowButton from './buttons/FollowButton';
import BookingButton from './buttons/BookingButton';
import WriteButton from '../../common/WriteButton';
import { AvatarCustom } from '@components';

interface SpotPreviewProps {
  spot: GetSpotDetailedQuery['spot'];
}

function IDCard(props: SpotPreviewProps) {
  const { spot } = props;
  const { avatar, name, events, addicts } = spot;
  const { account } = useSession();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation('cta');

  const avatarScale = isMobile ? 75 : 100;

  return (
    <>
      <Stack spacing={3} direction={isMobile ? 'column' : 'row'} width="100%">
        <Stack
          spacing={3}
          pt={isMobile ? 2 : 0}
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <AvatarCustom
            ressource={avatar?.url || null}
            id={account?.id || ''}
            type="account"
            alt={avatar?.url ? name + '-avatar' : ''}
            sx={{ width: avatarScale, height: avatarScale }}
          />
          <Stack spacing={3} direction="row" width="100%" justifyContent="space-evenly" pr={5}>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(addicts.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`addict${addicts.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
            <Stack alignItems="center">
              <Typography variant="body1" color="textPrimary" fontWeight="bold">
                {getCount(events.length)}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {`event${events.length > 1 ? 's' : ''}`}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1} width="100%">
          {account?.__typename !== 'Spot' && (
            <>
              <BookingButton spot={spot} />
              <Stack spacing={1} direction="row" width="100%">
                <FollowButton spotId={spot.id} isFollowing={spot.isFollowing} />
                <WriteButton recipientId={spot.id} />
              </Stack>
            </>
          )}
        </Stack>
      </Stack>
      <Stack spacing={3} direction="row" width="100%">
        <Typography variant="h4" color="textPrimary">
          {name}
        </Typography>
      </Stack>
    </>
  );
}

function getCount(number: number) {
  if (number < 1000) {
    return number;
  } else if (number < 1_000_000) {
    return `${Math.floor(number / 1000)}K`;
  } else if (number < 1_000_000_000) {
    return `${Math.floor(number / 1_000_000)}M`;
  } else if (number < 1_000_000_000_000) {
    return `${Math.floor(number / 1_000_000_000)}B`;
  }
}

export default IDCard;
