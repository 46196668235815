import { Helmet } from 'react-helmet-async';
import CssBaseline from '@mui/material/CssBaseline';
import { ToastContainer } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import { ScrollRestoration } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useAppTitle } from '@hooks';
import { Dialog, AuthenticationDialog, LoadingBackDrop } from '@components';

import DialogBackdrop from './DialogBackdrop';
import AppLoader from './AppLoader';
import DesktopApp from './desktop';
import BottomNavigation from './BottomNavigation';
import PreviewBooking from '../pages/me/booking';

export default function Layout() {
  const appTitle = useAppTitle();
  const theme = useTheme();
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <Helmet>
        <meta name="title" content={appTitle} />
      </Helmet>
      <CssBaseline />
      <Stack>
        <ScrollRestoration />
        <AuthenticationDialog />
        <Dialog />
        <PreviewBooking />
        <AppLoader />
        <LoadingBackDrop />
        <DesktopApp />
        <DialogBackdrop />
        {/* {isScreenMedium ? <BottomNavigation /> : null} */}
        <ToastContainer
          style={import.meta.env.MODE === 'development' ? { width: '500px' } : {}}
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeButton={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          theme="dark"
        />
      </Stack>
    </>
  );
}
