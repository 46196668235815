import * as React from 'react';
import Stack from '@mui/material/Stack';

import * as Device from 'react-device-detect';

interface WrapperProps {
  children: React.ReactNode;
}

export function SlidersWrapper(props: WrapperProps) {
  const { children } = props;
  const { isLandscape } = Device.useMobileOrientation();

  const isMobileLandscape = Device.isMobileOnly && isLandscape;

  return (
    <Stack
      spacing={Device.isMobileOnly ? 5 : 10}
      mt={isMobileLandscape ? 2 : '70vh'}
      sx={{ position: 'relative' }}
    >
      {children}
    </Stack>
  );
}

export function SliderWrapper(props: WrapperProps) {
  const { children } = props;
  const { isLandscape } = Device.useMobileOrientation();

  return (
    <Stack
      spacing={2}
      direction="row"
      px={{ xs: 2, sm: 3, md: 10 }}
      py={20}
      sx={{
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '& > :nth-of-type(1)': {
          '&:hover .container': {
            transformOrigin: 'center left',
          },
        },
        '& > :nth-last-of-type(1)': {
          '&:hover .container': {
            transformOrigin: 'center right',
          },
        },
      }}
    >
      {children}
    </Stack>
  );
}

export function SlideWrapper(props: WrapperProps) {
  const { children } = props;
  const { isLandscape } = Device.useMobileOrientation();

  const isMobileLandscape = Device.isMobileOnly && isLandscape;

  return (
    <Stack
      spacing={2}
      direction="row"
      px={{ xs: 2, sm: 3, md: 10 }}
      py={20}
      sx={{
        overflowX: 'scroll',
        scrollBehavior: 'smooth',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '& > :nth-of-type(1)': {
          '&:hover .container': {
            transformOrigin: 'center left',
          },
        },
        '& > :nth-last-of-type(1)': {
          '&:hover .container': {
            transformOrigin: 'center right',
          },
        },
      }}
    >
      {children}
    </Stack>
  );
}
