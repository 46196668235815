import { gql } from "@apollo/client";

export const IS_EMAIL_AVAILABLE = gql`
  query isEmailAvailable($email: String!) {
    isEmailAvailable(email: $email)
  }
`;

export const VERIFY_PASSWORD = gql`
  mutation isPasswordValid($password: String!) {
    isPasswordValid(password: $password)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation changePassword($password: String!) {
    changePassword(password: $password)
  }
`;

export const GET_SESSION = gql`
  query GetSession {
    session {
      id
      email
      firstname
      lastname
      gender
      birthdate
      mobilePhone
      preferences {
        language
        theme
      }
      isActive
      isVerified
      isDelete
      createdAt
      updatedAt
      deletedAt
      accounts {
        __typename
        ... on Consumer {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          friends {
            accepted {
              id
              name
              slug
              avatar {
                id
                url
                fileId
                fileName
                size
                mimeType
                createdAt
              }
            }
            pending {
              id
              name
              slug
              avatar {
                id
                url
                fileId
                fileName
                size
                mimeType
                createdAt
              }
            }
            sent {
              id
              name
              slug
              avatar {
                id
                url
                fileId
                fileName
                size
                mimeType
                createdAt
              }
            }
          }
          isPrivate
        }
        ... on Maker {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
        }
        ... on Spot {
          id
          isCurrent
          name
          slug
          avatar {
            id
            url
            fileId
            fileName
            size
            mimeType
            createdAt
          }
          isActive
          isVerified
          notifications {
            standby {
              all {
                email
                push
              }
            }
            request {
              message {
                email
                push
              }
              mention {
                email
                push
              }
            }
            booking {
              new {
                email
                push
              }
              edit {
                email
                push
              }
              late {
                email
                push
              }
              cancel {
                email
                push
              }
            }
            event {
              invitation {
                email
                push
              }
              full {
                email
                push
              }
              upcoming {
                email
                push
              }
              cancel {
                email
                push
              }
            }
            feedback {
              new {
                email
                push
              }
            }
          }
          atmosphere {
            id
            calm
            lively
            dancing
            updatedAt
          }
          bringable {
            id
            food
            drinks
            birthdayCake
            updatedAt
          }
          configuration {
            id
            averageTicket
            currency
            closeSpaces
            musicalGenre
            capacity
            entryPrice
            updatedAt
          }
          equipment {
            id
            amphitheater
            auditorium
            sono
            wifi
            stage
            video
            locker
            terrace
            toilets
            prmAccess
            dancefloor
            microphone
            prmToilets
            smokingRoom
            updatedAt
          }
          payment {
            id
            amex
            cash
            checks
            blueCard
            mealTickets
            updatedAt
          }
          service {
            id
            dj
            karaoke
            ownMusic
            entertainmentShow
            updatedAt
          }
          suitable {
            id
            bachelor
            birthday
            farewell
            teamMeal
            wendding
            afterwork
            exposition
            cocktailPro
            fashionShow
            businessMeal
            companyEvent
            friendsDrink
            studentParty
            productLaunch
            updatedAt
          }
          type {
            id
            amusementPark
            aquarium
            artGallery
            bar
            cafe
            campground
            concertHall
            casino
            departmentStore
            nightClub
            park
            restaurant
            sportClub
            stadium
            updatedAt
          }
        }
      }
    }
  }
`;

export const LOGIN_USER = gql`
  mutation login($input: LoginInput!) {
    login(input: $input)
  }
`;

export const REFRESH_TOKENS = gql`
  mutation refreshTokens($refreshToken: String!) {
    refreshTokens(refreshToken: $refreshToken) {
      access
      refresh
    }
  }
`;

export const LOGIN_USER_SSO = gql`
  mutation loginSSO($input: LoginSSOInput!) {
    loginSSO(input: $input) {
      __typename
      ... on Tokens {
        access
        refresh
      }
      ... on ProfileSSO {
        email
        firstname
        lastname
        avatar {
          id
          url
          fileId
          fileName
          size
          mimeType
          createdAt
        }
      }
    }
  }
`;

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($token: String!) {
    verifyEmail(token: $token)
  }
`;

export const RESEND_ACTIVATION_MAIL = gql`
  mutation resendActivationMail {
    resendActivationMail
  }
`;

export const LOGOUT_USER = gql`
  mutation logout {
    logout
  }
`;
