import { alpha, useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ListHomeSpotsQuery } from '@graphql';
import useStyles from './styles';

function getSpotTypes(obj: Record<string, boolean | string>): string {
  const { t } = useTranslation('spot');

  return Object.keys(obj)
    .filter((key) => obj[key] === true)
    .map((type) => t(`type.${type}`))
    .join(', ');
}

interface EventHighlightProps {
  spot: ListHomeSpotsQuery['homeSpots'][0]['spots'][0];
}

const SpotHighlight = (props: EventHighlightProps) => {
  const { spot } = props;
  const theme = useTheme();
  const styles = useStyles(theme, spot?.covers[0]?.url || '');
  const isScreenMedium = useMediaQuery(theme.breakpoints.down('md'));
  const types = getSpotTypes(spot.type) || '';
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('spot');

  const TopTen = () => {
    const size = isScreenMedium ? theme.spacing(6) : theme.spacing(10);

    return (
      <Stack
        width={size}
        height={size}
        alignItems="center"
        justifyContent="center"
        bgcolor="primary.main"
        borderRadius={theme.spacing(1)}
      >
        <Typography fontSize={isScreenMedium ? '.5rem' : '1rem'} fontWeight={800}>
          Top
        </Typography>
        <Typography fontSize={isScreenMedium ? '.8rem' : '1.5rem'} fontWeight={800}>
          10
        </Typography>
      </Stack>
    );
  };

  const handleOpen = () => {
    setSearchParams({ type: 'spot', q: spot.slug, tab: 'preview' }, { preventScrollReset: true });
  };

  return (
    <Stack justifyContent="center" px={{ xs: 2, sm: 5, md: 10 }} sx={styles.highlight}>
      <Stack width="100%" spacing={{ xs: 2, md: 5 }}>
        <Stack
          p={3}
          spacing={isScreenMedium ? 2 : 5}
          width={isScreenMedium ? '100%' : '50%'}
          sx={{
            borderRadius: theme.borderRadius,
            backgroundColor: alpha(theme.palette.background.default, 0.8),
          }}
        >
          <Typography variant="h3" fontWeight={800} sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}>
            {spot.name}
          </Typography>

          <Stack direction="row" spacing={2} alignItems={'center'}>
            <TopTen />
            <Stack>
              <Typography
                fontSize="1.3rem"
                component="h2"
                fontWeight={800}
                sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
              >
                N°7 des spots
              </Typography>
              <Typography
                fontSize="1.3rem"
                component="h3"
                fontWeight={800}
                color="GrayText"
                sx={{ textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
              >
                {types}
              </Typography>
            </Stack>
          </Stack>

          {!isScreenMedium && (
            <Typography
              fontSize="1.1rem"
              sx={{ color: 'white', textShadow: `1px 1px 5px ${alpha('#000', 0.8)}` }}
            >
              Là où l'art de la gastronomie se marie à une ambiance élégante. Situé en cœur de ville,
              découvrez des plats raffinés préparés avec des ingrédients locaux par nos chefs talentueux. A
              vos fourchettes pour une expérience culinaire inoubliable.
            </Typography>
          )}
          <Box width={isScreenMedium ? '100%' : '50%'}>
            <Button
              onClick={handleOpen}
              startIcon={<theme.icons.see />}
              variant="contained"
              color="primary"
              size={isScreenMedium ? 'small' : 'large'}
            >
              {t('See the spot', { ns: 'cta' })}
            </Button>
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SpotHighlight;
