import * as React from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { useSearchParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppContext } from '@context';
import { Event, ListHomeEventsQuery } from '@graphql';
import { truncateText } from '@utils';

const getLowestPrice = (
  tickets: ListHomeEventsQuery['eventCategories'][0]['events'][0]['tickets']
): ListHomeEventsQuery['eventCategories'][0]['events'][0]['tickets'][0] | null => {
  if (tickets.length === 0) return null;

  return tickets.reduce((lowest, current) => {
    return current.price < lowest.price ? current : lowest;
  });
};

const getCurrency = (currency: 'EUR' | 'USD' | 'GBP') => {
  const curMap = {
    EUR: '€',
    USD: '$',
    GBP: '£',
  };
  return curMap[currency];
};

interface SlideProps {
  event: ListHomeEventsQuery['eventCategories'][0]['events'][0];
}

const EventSlide = function (props: SlideProps) {
  const { event } = props;
  const theme = useTheme();
  const { dispatch } = useAppContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('event');

  const {
    id,
    title,
    from = '',
    tickets,
    covers,
    category,
    spot = {
      avatar: { url: '' },
      name: 'Compte inconnu',
    },
  } = event;

  const transition = {
    transitionDuration: '.3s',
    transitionTimingFunction: 'ease-in-out',
  };

  const lowestPrice = getLowestPrice(tickets);
  const address = event?.spot?.address?.label || event.address?.label;

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setSearchParams({ ...searchParams, type: 'event', q: id }, { preventScrollReset: true });
  };

  return (
    <Link to={`?type=event&q=${id}`} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <Stack
        alignItems="center"
        sx={{
          position: 'relative',
          minWidth: { xs: '300px', sm: '350px' },
          height: 'auto',
          aspectRatio: '4/3',
          borderRadius: theme.spacing(1),
          color: theme.palette.text.primary,
          '&:hover': {
            cursor: 'pointer',
            '.container': {
              zIndex: 1,
              transformOrigin: 'center',
              transform: 'scale(1.4)',
              borderRadius: theme.borderRadius,
              boxShadow: '0px 0px 20px rgba(0, 0, 0, 1)',
              ...transition,
            },
            '.top-infos': {
              height: 40,
              ...transition,
            },
            '.top-infos__title ': {
              fontSize: '.9rem',
              ...transition,
            },
            '.top-extras-infos': {
              opacity: 1,
              ...transition,
            },
            '.img': {
              width: '95%',
              boxShadow: '0px 5px 10px rgba(0, 0, 0, .5)',
              borderRadius: theme.borderRadius,
              ...transition,
            },
            '.bottom-infos': {
              height: 40,
              ...transition,
            },
            '.bottom-extras-infos': {
              span: {
                fontSize: '.6rem',
              },
              opacity: 1,
              ...transition,
            },
          },
        }}
      >
        <Stack
          className="container"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            zIndex: 0,
            position: 'absolute',
            width: '100%',
            borderRadius: theme.borderRadius,
            backgroundColor: theme.palette.background.paper,
            boxShadow: '0px 0px 20px rgba(0, 0, 0, .3)',
            ...transition,
          }}
        >
          <Stack
            className="top-infos"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '90%',
              height: 30,
              ...transition,
              mb: 1,
              mt: 1.5,
            }}
          >
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography className="top-infos__title" fontSize=".9rem" fontWeight="bold" sx={transition}>
                {truncateText(title, 30)}
              </Typography>
            </Stack>
            <Stack
              className="top-extras-infos"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                width: '100%',
                bottom: 0,
                opacity: 0,
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography variant="body 2" color="text.secondary" fontSize=".6rem" sx={transition}>
                {lowestPrice?.price
                  ? t('price.from', {
                      price: lowestPrice.price,
                      currency: getCurrency(lowestPrice.currency as 'EUR' | 'USD' | 'GBP'),
                    })
                  : ''}
              </Typography>
              <Stack spacing={1} direction="row" alignItems="center">
                <Typography
                  variant="body 2"
                  color="text.secondary"
                  fontSize=".6rem"
                  fontWeight="bold"
                  sx={transition}
                >
                  {truncateText(spot?.name || '', 25)}
                </Typography>
                <Avatar src={spot?.avatar?.url} alt={spot?.name + '-avatar'} sx={{ width: 24, height: 24 }} />
              </Stack>
            </Stack>
          </Stack>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '100%',
              aspectRatio: '16/9',
              ...transition,
            }}
          >
            <Box
              className="img"
              component="img"
              src={covers[0]?.url}
              alt={name + ' cover'}
              sx={{
                width: '100%',
                aspectRatio: '16/9',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 2,
                ...transition,
              }}
            />
          </Stack>
          <Stack
            className="bottom-infos"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              position: 'relative',
              width: '90%',
              height: 30,
              ...transition,
              mb: 1,
              mt: 1,
            }}
          >
            <Stack
              className="bottom-extras-infos"
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                width: '100%',
                top: 0,
                opacity: 0,
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography variant="body 2" color="text.secondary" fontSize=".6rem">
                {t('category.' + category?.name, { ns: 'common' })}
              </Typography>
              <Typography variant="body 2" color="text.secondary" fontSize=".6rem">
                {address ? address.split(',')[0] : t('word.unknown address', { ns: 'common' })}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                borderRadius: theme.borderRadius,
                ...transition,
              }}
            >
              <Typography
                className="bottom-infos__subtitle"
                variant="body 2"
                color="text.secondary"
                fontSize=".7rem"
                sx={transition}
              >
                {moment(from).format('dddd DD MMMM YYYY [à] HH[h]mm ')}
              </Typography>
              <Typography
                className="bottom-infos__subtitle"
                variant="body 2"
                color="text.secondary"
                fontSize=".7rem"
                sx={transition}
              >
                {event?.address?.city || t('word.unknown city', { ns: 'common' })}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Link>
  );
};

export default EventSlide;
