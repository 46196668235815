import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as Device from 'react-device-detect';
import { useSearchParams, Link } from 'react-router-dom';

import { truncateText } from '@utils';
import { ListHomeEventsQuery, ListHomeSpotsQuery } from '@graphql';

interface SlidesProps {
  children: React.ReactNode;
}

interface SlideProps {
  event?: ListHomeEventsQuery['eventCategories'][0]['events'][0];
  spot?: ListHomeSpotsQuery['homeSpots'][0]['spots'][0];
}

interface MobileSlideProps {
  type: 'Spot' | 'Event';
  title: string;
  covers: { id: string; url: string }[];
  subtitleLeft: string;
  subtitleRight: string;
  to: string;
}

export function MobileSlide(props: MobileSlideProps) {
  const { type, to, title = '', subtitleLeft = '', subtitleRight = '', covers = [] } = props;
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();

  let toPath = '';

  if (type === 'Spot') {
    toPath = `?type=spot&q=${to}&tab=preview`;
  } else if (type === 'Event') {
    toPath = `?type=event}&q=${to}`;
  }

  const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    const link = { type: type.toLowerCase(), q: to };

    if (type === 'Spot') {
      Object.assign(link, { tab: 'preview' });
    }

    setSearchParams(link, { preventScrollReset: true });
  };

  return (
    <Link to={toPath} style={{ textDecoration: 'none' }} onClick={handleOnClick}>
      <Stack
        spacing={0}
        alignItems="center"
        sx={{
          minWidth: { xs: '300px', sm: '350px' },
          borderRadius: theme.spacing(1),
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: '100%',
            aspectRatio: '16/9',
            boxShadow: '0px 3px 5px rgba(0, 0, 0, .5)',
          }}
        >
          {covers?.length ? (
            <Box
              className="img"
              component="img"
              src={covers[0]?.url}
              alt={name + ' cover'}
              sx={{
                width: '100%',
                aspectRatio: '16/9',
                objectFit: 'cover',
                objectPosition: 'center',
                borderRadius: 2,
              }}
            />
          ) : (
            <Stack
              className="img"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: '100%',
                aspectRatio: '16/9',
              }}
            >
              <theme.icons.noImage fontSize="large" />
            </Stack>
          )}
        </Stack>
        <Stack width="100%" p={1}>
          <Typography className="top-infos__title" fontSize=".9rem" fontWeight="bold">
            {truncateText(title, 30)}
          </Typography>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" fontSize=".8rem">
              {truncateText(subtitleLeft, 25)}
            </Typography>
            <Typography variant="body2" fontSize=".7rem">
              {subtitleRight}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Link>
  );
}

export function DesktopSlide(props: SlidesProps) {
  const { children } = props;
  const { isLandscape } = Device.useMobileOrientation();

  const isMobileLandscape = Device.isMobileOnly && isLandscape;

  return (
    <Stack spacing={10} mt={isMobileLandscape ? 2 : '70vh'} sx={{ position: 'relative' }}>
      {children}
    </Stack>
  );
}

export function Slide(props: SlidesProps) {
  const { children } = props;
  const { isLandscape } = Device.useMobileOrientation();

  const isMobileLandscape = Device.isMobileOnly && isLandscape;

  return (
    <Stack spacing={10} mt={isMobileLandscape ? 2 : '70vh'} sx={{ position: 'relative' }}>
      {children}
    </Stack>
  );
}
