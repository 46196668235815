import { useTheme, alpha } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

interface PlanProps {
  type: 'consumer' | 'spot' | 'artist';
  name: string;
  price: string;
  incentive: string;
  color: string;
  features: {
    price: string;
    items: { [k: string]: boolean }[];
  }[];
  handleChooseType: (type: PlanProps['type']) => void;
}

interface PlansProps {
  handleChooseType: (type: PlanProps['type']) => void;
}

export default function Plans(props: PlansProps) {
  const { handleChooseType } = props;
  const theme = useTheme();

  return (
    <Stack
      spacing={1}
      width="100%"
      direction="row"
      justifyContent="center"
      divider={
        <Divider
          orientation="vertical"
          sx={{
            backgroundColor: theme.palette.grey[800],
          }}
        />
      }
    >
      <Plan
        type="consumer"
        name="Perso"
        price="Gratuit"
        color="#7cc2dd"
        incentive="Ton compte personnel"
        features={[
          {
            price: 'Gratuit',
            items: [
              { 'Messagerie instantanée': true },
              { 'Créer des évènements privés': true },
              { 'Créer des évènements publiques': false },
              { 'Réserver des évènement*': true },
              { 'Réserver des spots': true },
            ],
          },
        ]}
        handleChooseType={handleChooseType}
      />
      <Plan
        type="spot"
        name="Spot"
        price="Sur mesure"
        color="#dadd7c"
        incentive="Fais le vivre"
        features={[
          {
            price: 'Gratuit',
            items: [
              { 'Messagerie instantanée': true },
              { 'Créer des évènements privés': true },
              { 'Créer des évènements publiques*': true },
            ],
          },
          {
            price: 'Sur mesure',
            items: [
              { 'Statistiques avancées': true },
              { "Catalogue d'artistes dédié": true },
              { 'Gestion des réservations': true },
              { ['Billeterie']: true },
            ],
          },
        ]}
        handleChooseType={handleChooseType}
      />
      {/* <Plan
        type="artist"
        name="Artiste"
        price="Sur mesure"
        color="#7cdd87"
        incentive="Augmente ta visibilité"
        features={[
          {
            price: 'Gratuit',
            items: [
              { 'Messagerie instantanée': true },
              { 'Créer des évènements privés': true },
              { 'Créer des évènements publiques*': true },
            ],
          },
          {
            price: 'Sur mesure',
            items: [{ 'Statistiques avancées': true }, { 'Catalogue de spots dédié': true }],
          },
        ]}
        handleChooseType={handleChooseType}
      /> */}
    </Stack>
  );
}

function Plan(props: PlanProps) {
  const { type, name, price, incentive, color, features, handleChooseType } = props;
  const theme = useTheme();

  const textColor = 'black';

  return (
    <Stack
      spacing={3}
      width="100%"
      maxWidth="calc(100% / 3)"
      height="100%"
      justifyContent="flex-start"
      alignItems="center"
      p={theme.spacing(5)}
      sx={{
        boxShadow: '0 5px 10px rgba(0, 0, 0, 0.5)',
        borderRadius: '10px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        backgroundColor: color,
      }}
    >
      <Stack width="100%">
        <Typography variant="h4" color={textColor} textAlign="left" fontWeight={800} width="100%">
          {name}
        </Typography>
        <Typography variant="body1" color={textColor} textAlign="left" width="100%" fontWeight={600}>
          {incentive}
        </Typography>
      </Stack>
      <Stack spacing={3} width="100%" height="100%" justifyContent="flex-start" alignItems="flex-start">
        {features.map((feature, index) => (
          <Stack key={index} width="100%">
            <Divider
              sx={{
                width: '100%',
                '&::before, &::after': { borderColor: alpha('#000', 0.3) },
                color: 'black',
                fontSize: '1.1rem',
                fontWeight: 800,
                marginBottom: '1rem',
              }}
            >
              {feature.price}
            </Divider>
            {feature.items.map((item) =>
              Object.entries(item).map(([feat, active]) => (
                <Stack key={index} spacing={2} direction="row">
                  {active ? (
                    <theme.icons.check sx={{ color: 'black' }} />
                  ) : (
                    <theme.icons.close sx={{ color: 'black' }} />
                  )}
                  <Typography
                    fontSize=".8rem"
                    variant="body1"
                    color={textColor}
                    textAlign="left"
                    fontWeight={600}
                  >
                    {feat}
                  </Typography>
                </Stack>
              ))
            )}
          </Stack>
        ))}
      </Stack>
      <Button
        onClick={() => handleChooseType(type)}
        type="button"
        variant="contained"
        color="uncolored"
        sx={{ marginTop: 'auto' }}
      >
        Je choisis
      </Button>
    </Stack>
  );
}
