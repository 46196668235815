import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import Stack from '@mui/material/Stack';
import DialogContent from '@mui/material/DialogContent';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';
import { useSearchParams } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { APP_TITLE, truncateText } from '@utils';
import { useGetSpotDetailedLazyQuery } from '@graphql';
import Header from './Header';
import Preview from './preview';
import Agenda from './agenda';
import Rex from './rex';

interface SpotDialogProps {
  handleClose: () => void;
  handleBack: () => void;
}

const validTabs = ['preview', 'menu', 'calendar', 'rex'];

function SpotDialog(props: SpotDialogProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = React.useState(0);
  const swiperRef = React.useRef<SwiperClass>();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const padding = isMobile ? 1 : 3;

  const [getSpot, { data }] = useGetSpotDetailedLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  React.useEffect(() => {
    const type = searchParams.get('type');
    const slug = searchParams.get('q');
    const tab = searchParams.get('tab');
    const tabIndex = tab !== null ? validTabs.indexOf(tab) : 0;

    if (type === 'spot' && slug && slug !== data?.spot?.slug) {
      getSpot({ variables: { slug } });
    }

    if (slug && (!tab || !validTabs.includes(tab as string))) {
      setSearchParams(
        {
          q: slug,
          type: 'spot',
          tab: 'preview',
        },
        { preventScrollReset: true, replace: true }
      );
    } else if (Number.isInteger(tabIndex)) {
      setActiveTab(tabIndex);
    }
  }, [searchParams]);

  if (!data?.spot) {
    return null;
  }

  const handleTabChange = (event: React.SyntheticEvent, newTab: number) => {
    setActiveTab(newTab);
    setSearchParams(
      {
        q: data.spot.slug,
        type: 'spot',
        tab: validTabs[newTab],
      },
      { preventScrollReset: true, replace: true }
    );

    if (swiperRef.current) {
      swiperRef.current.slideTo(newTab);
    }
  };

  const handleSwiperSlideChange = (swiper: any) => {
    setActiveTab(swiper.activeIndex);
  };

  const PAGE_TITLE = `${APP_TITLE} | ${data.spot.name} spot`;
  const PAGE_DESCRIPTION = truncateText(data.spot?.description || 'Spot à découvrir', 100);
  const PAGE_IMAGE = data.spot.covers[0]?.url || '';
  const PAGE_URL = window.location.href;

  const { covers } = data.spot;

  return (
    <>
      <Helmet>
        {/* BASE OPEN GRAPH */}
        <meta property="og:title" content={PAGE_TITLE} />
        <meta property="og:description" content={PAGE_DESCRIPTION} />
        <meta property="og:image" content={PAGE_IMAGE} />
        <meta property="og:url" content={PAGE_URL} />
        <meta property="og:type" content="website" />

        {/* TWITTER OPEN GRAPH */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={PAGE_TITLE} />
        <meta name="twitter:description" content={PAGE_DESCRIPTION} />
        <meta name="twitter:image" content={PAGE_IMAGE} />
      </Helmet>
      <Stack sx={{ position: 'relative', width: '100%' }}>
        <Stack sx={{ position: 'relative' }}>
          <Header
            handleBack={props.handleBack}
            covers={covers}
            activeTab={activeTab}
            handleTabChange={handleTabChange}
          />
        </Stack>
        <DialogContent sx={{ p: 0, width: '100%' }}>
          <Stack spacing={5} p={padding}>
            <Swiper
              onSlideChange={handleSwiperSlideChange}
              onSwiper={(swiper) => (swiperRef.current = swiper)}
              slidesPerView={1}
              style={{ marginTop: 0 }}
              initialSlide={activeTab}
            >
              <SwiperSlide>{activeTab === 0 && <Preview spot={data.spot} />}</SwiperSlide>
              <SwiperSlide>{activeTab === 1 && <>Menu</>}</SwiperSlide>
              <SwiperSlide>{activeTab === 2 && <Agenda slug={data.spot.slug} />}</SwiperSlide>
              <SwiperSlide>{activeTab === 3 && <Rex spot={data.spot} />}</SwiperSlide>
            </Swiper>
          </Stack>
        </DialogContent>
      </Stack>
    </>
  );
}

export default React.memo(SpotDialog);
