import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import * as Device from 'react-device-detect';
import moment from 'moment';

import { ListHomeEventsQuery } from '@graphql';
import { SliderWrapper, MobileSlide } from '@components';
import Slide from './EventSlide';

interface SliderProps {
  title: string;
  events: ListHomeEventsQuery['eventCategories'][0]['events'];
}

const EventSlider = (props: SliderProps) => {
  const { title, events } = props;
  const { t } = useTranslation('common');

  return (
    <Stack width="100%" justifyContent="space-between">
      <Typography pl={{ xs: 2, sm: 3, md: 10 }} variant="h5" fontWeight={'bold'}>
        {t('category.' + title)}
      </Typography>
      <Box sx={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            margin: '-150px 0',
          }}
        >
          <SliderWrapper>
            {events.map((event) =>
              Device.isDesktop ? (
                <Slide key={event.id} event={event} />
              ) : (
                <MobileSlide
                  key={event.id}
                  to={event.id}
                  type="Event"
                  title={event.title}
                  covers={event.covers.map(({ id, url }) => ({
                    id,
                    url,
                  }))}
                  subtitleLeft={moment(event.from).format('dddd DD MMMM')}
                  subtitleRight={event.spot?.address?.city || event.address?.city || t('word.unknown city')}
                />
              )
            )}
          </SliderWrapper>
        </Box>
      </Box>
    </Stack>
  );
};

export default EventSlider;
