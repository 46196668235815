import { Navigate, createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';

import Layout from '../layout';

import LostPage from 'pages/404/LostPage';

import EventsPage from 'pages/events/EventsPage';
import EventsErrorBoundary from 'pages/events/ErrorBoudary';

import MakersPage from 'pages/makers/MakersPage';
import MakersErrorBoundary from 'pages/makers/ErrorBoudary';

import SpotsPage from 'pages/spots/SpotsPage';
import SpotsErrorBoundary from 'pages/spots/ErrorBoudary';

import { useSession } from '@hooks';
import { RequireRole } from '.';
import ErrorBoundary from './ErrorBoudary';

import { MePage, Calendar, Table, Stats } from 'pages/me';

export const buildRouter = (session: ReturnType<typeof useSession>) => {
  const { account } = session;
  const role = account?.__typename ?? null;

  const meRoleRoutesMap: Record<string, RouteObject[]> = {
    Guest: [],
    Consumer: [
      {
        id: 'me-account',
        path: 'account',
        // element: <Calendar />,
        children: [
          {
            id: 'me-account-preview',
            path: 'account-preview',
            element: <>Account preview</>,
          },
          {
            id: 'me-account-verify',
            path: 'account-verify',
            element: <>Account preview</>,
          },
        ],
      },
      {
        id: 'me-calendar',
        path: 'calendar',
        element: <Calendar />,
        children: [
          {
            id: 'me-calendar-booking-detail',
            path: ':bookingId',
            element: null,
          },
          {
            id: 'me-calendar-event-detail',
            path: ':eventId',
            element: null,
          },
        ],
      },
      {
        id: 'me-table',
        path: 'table',
        element: <Table />,
        children: [
          {
            id: 'me-table-booking-detail',
            path: ':bookingId',
            element: null,
          },
          {
            id: 'me-table-event-detail',
            path: ':eventId',
            element: null,
          },
        ],
      },
    ],
    Spot: [
      {
        id: 'me-spot-account',
        path: 'account',
        // element: <Calendar />,
        children: [
          {
            id: 'me-spot-account-preview',
            path: 'account-preview',
            element: <>Account preview</>,
          },
          {
            id: 'me-spot-account-verify',
            path: 'account-verify',
            element: <>Account preview</>,
          },
        ],
      },
      {
        id: 'me-booking',
        path: 'booking',
        element: <Outlet />,
        children: [
          {
            id: 'me-booking-calendar',
            path: 'calendar',
            element: <Calendar />,
            children: [
              {
                id: 'me-booking-calendar-detail',
                path: ':bookingId',
                element: null,
              },
            ],
          },
          {
            id: 'me-booking-table',
            path: 'table',
            element: <Table />,
            children: [
              {
                id: 'me-booking-table-detail',
                path: ':bookingId',
                element: null,
              },
            ],
          },
          {
            id: 'me-booking-stats',
            path: 'stats',
            element: <Stats />,
          },
        ],
      },
      {
        id: 'me-event',
        path: 'event',
        children: [
          {
            id: 'me-event-calendar',
            path: 'calendar',
            element: <Calendar />,
            children: [
              {
                id: 'me-event-calendar-detail',
                path: ':eventId',
                element: null,
              },
            ],
          },
          {
            id: 'me-event-table',
            path: 'table',
            element: <Table />,
            children: [
              {
                id: 'me-event-table-detail',
                path: ':eventId',
                element: null,
              },
            ],
          },
          {
            id: 'me-event-stats',
            path: 'stats',
            element: <Stats />,
          },
        ],
      },
    ],
  };

  console.log(`[{meRoleRoutesMap[role ?? 'Guest']}]:`, meRoleRoutesMap[role ?? 'Guest']);

  return createBrowserRouter([
    {
      id: 'app',
      path: '/',
      element: <Layout />,
      ErrorBoundary,
      children: [
        // UNLLOGGED USER OR CONSUMER PAGES
        {
          index: true,
          element: role === 'Spot' ? <Navigate to="/me" /> : <Navigate to="/events" />,
        },
        {
          id: 'events',
          path: 'events',
          element: (
            <RequireRole role={role} allowedRoles={[null, 'Consumer']}>
              <EventsPage />
            </RequireRole>
          ),
          ErrorBoundary: EventsErrorBoundary,
        },
        {
          id: 'spots',
          path: 'spots',
          element: (
            <RequireRole role={role} allowedRoles={[null, 'Consumer']}>
              <SpotsPage />
            </RequireRole>
          ),
          ErrorBoundary: SpotsErrorBoundary,
        },
        {
          id: 'makers',
          path: 'makers',
          element: (
            <RequireRole role={role} allowedRoles={[null, 'Consumer']}>
              <MakersPage />
            </RequireRole>
          ),
          ErrorBoundary: MakersErrorBoundary,
        },
        // LOGGED USER
        {
          id: 'me',
          path: 'me',
          element: (
            <RequireRole role={role} allowedRoles={['Consumer', 'Spot']}>
              <MePage />
            </RequireRole>
          ),
          children: meRoleRoutesMap[role ?? 'Guest'],
        },
        {
          id: '404',
          path: '*',
          element: <LostPage />,
        },
      ],
    },
  ]);
};
