import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ListItemButtonProps } from '@mui/material/ListItemButton';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import * as Device from 'react-device-detect';

import { Logo } from '@components';
import { loginStatusVar } from '@context';
import { capitalizeFirstLetter } from '@utils';
import LoginDialogForm from './api/LoginDialogForm';

export interface ILoginDialogProps {
  open?: boolean;
  button?: React.ReactElement<ListItemButtonProps>;
}

const GOOGLE_SSO_URL = `https://accounts.google.com/o/oauth2/v2/auth?scope=profile&access_type=offline&include_granted_scopes=true&response_type=code&state=state_parameter_passthrough_value&redirect_uri=${encodeURI(
  `${import.meta.env.VITE_APP_URI}/connect/google`
  // 'http://localhost/connect/google'
)}&client_id=${import.meta.env.VITE_GOOGLE_CLIENT_ID}
`;

export default function Login(props: ILoginDialogProps) {
  const theme = useTheme();
  const loginStatus = useReactiveVar(loginStatusVar);
  const { isLandscape } = Device.useMobileOrientation();
  const { t } = useTranslation('authentication');

  const isLandscapeMobile = Device.isMobileOnly && isLandscape;

  React.useEffect(() => {
    console.log(`[{Device}]:`, Device);
  }, [Device]);

  const ConnexionAlert = () => {
    if (loginStatus.isSessionExpired) {
      return <Alert severity="info">{t('alert.Session has expired')}</Alert>;
    } else if (loginStatus.shouldLogFirst) {
      return <Alert severity="info">{t('alert.You first need to connect')}</Alert>;
    }
    return null;
  };

  if (isLandscapeMobile) {
    return (
      <Stack width="100%" height="100%" justifyContent="space-between" spacing={3}>
        <Stack direction="row" justifyContent="space-between">
          <Stack flex={1}>
            <Logo clickable={false} size={2.5} />
          </Stack>
          <Stack flex={2}>
            <ConnexionAlert />
          </Stack>
        </Stack>
        <Stack height="100%" direction="row" spacing={3}>
          <Stack flex={2} spacing={3}>
            <Divider
              sx={{
                width: '100%',
                '&::before, &::after': { borderColor: theme.palette.grey[500] },
                color: theme.palette.text.secondary,
              }}
            >
              <Typography variant="h6" color="textPrimary">
                {t('button.Connection')}
              </Typography>
            </Divider>
            <Stack
              spacing={3}
              direction="row"
              height="100%"
              divider={
                <Divider
                  sx={{ backgroundColor: theme.palette.grey[800] }}
                  orientation="vertical"
                  variant="middle"
                  flexItem
                />
              }
            >
              <Stack flex={1} spacing={1} height="100%">
                <Button
                  fullWidth
                  variant="contained"
                  component={Link}
                  to={GOOGLE_SSO_URL}
                  sx={{
                    height: '100%',
                    bgcolor: 'white',
                    color: 'black',
                    '&:hover': {
                      bgcolor: 'white',
                    },
                  }}
                  startIcon={<GoogleIcon />}
                >
                  {t('button.Connect with', { provider: 'Google' })}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    height: '100%',
                    backgroundColor: '#3B5998',
                    '&:hover': {
                      bgcolor: '#3B5998',
                    },
                  }}
                  startIcon={<FacebookIcon />}
                >
                  {t('button.Connect with', { provider: 'Facebook' })}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{
                    height: '100%',
                    backgroundColor: 'black',
                    '&:hover': {
                      bgcolor: 'black',
                    },
                  }}
                  startIcon={<AppleIcon />}
                >
                  {t('button.Connect with', { provider: 'Apple' })}
                </Button>
              </Stack>
              <Stack flex={1} height="100%">
                <LoginDialogForm />
              </Stack>
            </Stack>
          </Stack>
          <Stack flex={1} spacing={3}>
            <Divider
              sx={{
                width: '100%',
                '&::before, &::after': { borderColor: theme.palette.grey[500] },
                color: theme.palette.text.secondary,
              }}
            >
              <Typography variant="h6" color="textPrimary">
                {t('Registration')}
              </Typography>
            </Divider>
            <Paper
              elevation={5}
              p={3}
              sx={{ backgroundColor: theme.palette.grey[800], p: 3, height: '100%' }}
            >
              <Stack height="100%" justifyContent="space-between">
                <Typography variant="h6" textAlign="center">
                  {t('Do you want to join the community?')}
                </Typography>
                <Typography variant="body1" textAlign="center">
                  {t('Sign up to enjoy all the benefits!')}
                </Typography>
                <Button fullWidth variant="contained">
                  {t('signup', { ns: 'cta' })}
                </Button>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack width="100%" height="100%" spacing={3} justifyContent="center" alignItems="center">
      <Logo clickable={false} size={2.5} />
      <ConnexionAlert />
      <Divider
        sx={{
          width: '100%',
          '&::before, &::after': { borderColor: theme.palette.grey[500] },
          color: theme.palette.text.secondary,
        }}
      >
        <Typography variant="h6" color="textPrimary">
          {t('button.Connection')}
        </Typography>
      </Divider>
      <Stack direction={isLandscapeMobile ? 'row' : 'column'} spacing={3} width="100%">
        <Button
          fullWidth
          variant="contained"
          size="small"
          component={Link}
          to={GOOGLE_SSO_URL}
          sx={{
            bgcolor: 'white',
            color: 'black',
            '&:hover': {
              bgcolor: 'white',
            },
          }}
          startIcon={<GoogleIcon />}
        >
          {t('button.Connect with', { provider: 'Google' })}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="small"
          sx={{
            backgroundColor: '#3B5998',
            '&:hover': {
              bgcolor: '#3B5998',
            },
          }}
          startIcon={<FacebookIcon />}
        >
          {t('button.Connect with', { provider: 'Facebook' })}
        </Button>
        <Button
          fullWidth
          variant="contained"
          size="small"
          sx={{
            backgroundColor: 'black',
            '&:hover': {
              bgcolor: 'black',
            },
          }}
          startIcon={<AppleIcon />}
        >
          {t('button.Connect with', { provider: 'Apple' })}
        </Button>
      </Stack>
      <Divider
        sx={{
          width: '100%',
          '&::before, &::after': { borderColor: theme.palette.grey[500] },
          color: theme.palette.text.secondary,
        }}
      >
        {t('text.or')}
      </Divider>
      <LoginDialogForm />
      <Divider
        sx={{
          width: '100%',
          '&::before, &::after': { borderColor: theme.palette.grey[500] },
          color: theme.palette.text.secondary,
        }}
      >
        {t('No account yet?')}
      </Divider>
      <Button size="small" fullWidth variant="contained">
        {t('signup', { ns: 'cta' })}
      </Button>
    </Stack>
  );
}
