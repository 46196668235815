import { ComponentType } from "react";
import type {} from "@mui/lab/themeAugmentation";
import { createTheme, Theme, alpha } from "@mui/material/styles";
import type {} from '@mui/x-data-grid/themeAugmentation';
import { grey } from "@mui/material/colors";

import WebIcon from "@mui/icons-material/Web";
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListIcon from '@mui/icons-material/List';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import AirlineSeatIndividualSuiteIcon from '@mui/icons-material/AirlineSeatIndividualSuite';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import RestorePageIcon from '@mui/icons-material/RestorePage';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LanguageIcon from '@mui/icons-material/Language';
import StarsIcon from '@mui/icons-material/Stars';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import DashboardIcon from '@mui/icons-material/Dashboard';
import GroupsIcon from '@mui/icons-material/Groups';
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/Info';
import TelegramIcon from '@mui/icons-material/Telegram';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GridOnIcon from '@mui/icons-material/GridOn';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import TimelineIcon from '@mui/icons-material/Timeline';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import DeleteIcon from '@mui/icons-material/Delete';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import PersonAdd from '@mui/icons-material/PersonAdd';
import ShieldIcon from '@mui/icons-material/Shield';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import EventIcon from '@mui/icons-material/Event';
import DateRangeIcon from '@mui/icons-material/DateRange';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import ReviewsIcon from '@mui/icons-material/Reviews';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import StoreIcon from '@mui/icons-material/Store';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import FlareIcon from '@mui/icons-material/Flare';
import SendIcon from '@mui/icons-material/Send';
import SpaIcon from '@mui/icons-material/Spa';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CloseIcon from '@mui/icons-material/Close';
import PreviewIcon from '@mui/icons-material/Preview';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import CheckIcon from '@mui/icons-material/Check';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ModeIcon from '@mui/icons-material/Mode';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import RestoreIcon from '@mui/icons-material/Restore';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ImageIcon from '@mui/icons-material/Image';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import GitHubIcon from '@mui/icons-material/GitHub';
import SearchIcon from '@mui/icons-material/Search';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import ShareIcon from '@mui/icons-material/Share';
import Logout from '@mui/icons-material/Logout';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

import { SvgIconProps } from '@mui/material/SvgIcon';

declare module '@mui/material/styles' {
  interface CustomTheme {
    borderRadius: number;
    boxShadow: string;
    elevation: number;
    icons: {
      logo: ComponentType<SvgIconProps>;
      webpage: ComponentType<SvgIconProps>;
      account: ComponentType<SvgIconProps>;
      booking: ComponentType<SvgIconProps>;
      store: ComponentType<SvgIconProps>;
      calendar: ComponentType<SvgIconProps>;
      inbox: ComponentType<SvgIconProps>;
      lock: ComponentType<SvgIconProps>;
      dashboard: ComponentType<SvgIconProps>;
      // actions
      close: ComponentType<SvgIconProps>;
      back: ComponentType<SvgIconProps>;
      manageAccount: ComponentType<SvgIconProps>;
      delete: ComponentType<SvgIconProps>;
      favorite: ComponentType<SvgIconProps>;
      favoriteOutlined: ComponentType<SvgIconProps>;
      deleteOutlined: ComponentType<SvgIconProps>;
      deleteForever: ComponentType<SvgIconProps>;
      add: ComponentType<SvgIconProps>;
      send: ComponentType<SvgIconProps>;
      options: ComponentType<SvgIconProps>;
      publish: ComponentType<SvgIconProps>;
      draft: ComponentType<SvgIconProps>;
      addPerson: ComponentType<SvgIconProps>;
      change: ComponentType<SvgIconProps>;
      restore: ComponentType<SvgIconProps>;
      logout: ComponentType<SvgIconProps>;
      restoreTrash: ComponentType<SvgIconProps>;
      edit: ComponentType<SvgIconProps>;
      see: ComponentType<SvgIconProps>;
      hide: ComponentType<SvgIconProps>;
      openNew: ComponentType<SvgIconProps>;
      signup: ComponentType<SvgIconProps>;
      share: ComponentType<SvgIconProps>;
      search: ComponentType<SvgIconProps>;
      news: ComponentType<SvgIconProps>;
      github: ComponentType<SvgIconProps>;
      upload: ComponentType<SvgIconProps>;
      interested: ComponentType<SvgIconProps>;
      home: ComponentType<SvgIconProps>;
      rocket: ComponentType<SvgIconProps>;
      summary: ComponentType<SvgIconProps>;
      restorePage: ComponentType<SvgIconProps>;
      // notifications
      request: ComponentType<SvgIconProps>;
      feedback: ComponentType<SvgIconProps>;
      telegram: ComponentType<SvgIconProps>;
      // others
      info: ComponentType<SvgIconProps>;
      artist: ComponentType<SvgIconProps>;
      noImage: ComponentType<SvgIconProps>;
      language: ComponentType<SvgIconProps>;
      email: ComponentType<SvgIconProps>;
      event: ComponentType<SvgIconProps>;
      week: ComponentType<SvgIconProps>;
      price: ComponentType<SvgIconProps>;
      grid: ComponentType<SvgIconProps>;
      location: ComponentType<SvgIconProps>;
      people: ComponentType<SvgIconProps>;
      spaces: ComponentType<SvgIconProps>;
      check: ComponentType<SvgIconProps>;
      unfold: ComponentType<SvgIconProps>;
      image: ComponentType<SvgIconProps>;
      flare: ComponentType<SvgIconProps>;
      vege: ComponentType<SvgIconProps>;
      expandMore: ComponentType<SvgIconProps>;
      expandLess: ComponentType<SvgIconProps>;
      chevron: ComponentType<SvgIconProps>;
      settings: ComponentType<SvgIconProps>;
      shield: ComponentType<SvgIconProps>;
      userGear: ComponentType<SvgIconProps>;
      ticket: ComponentType<SvgIconProps>;
      notification: ComponentType<SvgIconProps>;
      notificationEdit: ComponentType<SvgIconProps>;
      list: ComponentType<SvgIconProps>;
      preview: ComponentType<SvgIconProps>;
      timeline: ComponentType<SvgIconProps>;
      timelapse: ComponentType<SvgIconProps>;
      sleep: ComponentType<SvgIconProps>;
      restaurant: ComponentType<SvgIconProps>;
      volMin: ComponentType<SvgIconProps>;
      volMid: ComponentType<SvgIconProps>;
      volMax: ComponentType<SvgIconProps>;
      music: ComponentType<SvgIconProps>;
    };
  }

  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

const icons = {
  logo: BlurOnIcon,
  // pages
  webpage: WebIcon,
  booking: RoomServiceIcon,
  store: StoreIcon,
  account: AccountCircleIcon,
  calendar: CalendarMonthIcon,
  inbox: InboxIcon,
  lock: LockIcon,
  settings: SettingsIcon,
  shield: ShieldIcon,
  userGear: SettingsIcon,
  home: HomeIcon,
  github: GitHubIcon,
  news: NewspaperIcon,
  dashboard: DashboardIcon,
  // Actions
  search: SearchIcon,
  change: ChangeCircleIcon,
  back: ChevronLeftIcon,
  spaces: SpaceDashboardIcon,
  add: AddIcon,
  openNew: OpenInNewIcon,
  close: CloseIcon,
  chevron: ChevronRightIcon,
  delete: DeleteIcon,
  addPerson: PersonAdd,
  share: ShareIcon,
  deleteOutlined: DeleteOutlineIcon,
  deleteForever: DeleteForeverIcon,
  favorite: FavoriteIcon,
  favoriteOutlined: FavoriteBorderIcon,
  manageAccount: ManageAccountsIcon,
  expandLess: ExpandLess,
  logout: Logout,
  unfold: UnfoldMoreIcon,
  expandMore: ExpandMore,
  options: MoreVertIcon,
  send: SendIcon,
  interested: LightbulbIcon,
  publish: TaskAltIcon,
  draft: UnpublishedIcon,
  restore: RestoreIcon,
  restoreTrash: RestoreFromTrashIcon,
  edit: ModeIcon,
  see: Visibility,
  hide: VisibilityOff,
  signup: AppRegistrationIcon,
  rocket: RocketLaunchIcon,
  summary: SummarizeIcon,
  restorePage: RestorePageIcon,
  upload: CloudUploadIcon,
  // notifications
  request: UpcomingIcon,
  feedback: ReviewsIcon,
  telegram: TelegramIcon,
  // others
  artist: StarsIcon,
  info: InfoIcon,
  language: LanguageIcon,
  noImage: ImageNotSupportedIcon,
  people: GroupsIcon,
  event: EventIcon,
  week: DateRangeIcon,
  email: AlternateEmailIcon,
  price: AttachMoneyIcon,
  location: LocationOnIcon,
  check: CheckIcon,
  flare: FlareIcon,
  grid: GridOnIcon,
  ticket: LocalActivityIcon,
  vege: SpaIcon,
  notification: NotificationsActiveIcon,
  notificationEdit: EditNotificationsIcon,
  image: ImageIcon,
  list: ListIcon,
  preview: PreviewIcon,
  timeline: TimelineIcon,
  timelapse: TimelapseIcon,
  sleep: AirlineSeatIndividualSuiteIcon,
  restaurant: RestaurantIcon,
  volMin: VolumeMuteIcon,
  volMid: VolumeDownIcon,
  volMax: VolumeUpIcon,
  music: MusicNoteIcon,
};

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

// define light theme colors
export const lightTheme: Theme = createTheme({
  typography: {
    fontFamily: '"Exo", "Roboto", "Helvetica", "Arial", "Style Script", sans-serif',
  },
  // components: {
  //   MuiDataGrid: {
  //     styleOverrides: {
  //       root: {
  // backgroundColor: 'red',
  //       },
  //     },
  //   },
  // },
  palette: {
    primary: {
      main: '#6100ff',
      light: '#a066ff',
      dark: '#3a0099',
    },
    secondary: {
      main: '#E7901C',
      light: '#ffa93a',
      dark: 'cc7606',
    },
    background: {
      default: '#EFF2F5',
    },
    uncolored: createColor('#939393'),
  },
  borderRadius: 3,
  boxShadow: `0px 5px 10px ${alpha(grey[300], 1)}`,
  elevation: 10,
  icons,
});

// define dark theme colors
export const darkTheme: Theme = createTheme({
  typography: {
    fontFamily: '"Exo", "Roboto", "Helvetica", "Arial", "Style Script", sans-serif',
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: '#FFFFFF',
          },
          '& label.Mui-focused': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          // color: '#FFFFFF',
          color: '#CCCCCC',
          '&.Mui-focused': {
            color: '#FFFFFF',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#999999',
          },
          '&:hover fieldset': {
            borderColor: '#CCCCCC',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6100ff',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          '& fieldset': {
            borderColor: '#999999',
          },
          '&:hover fieldset': {
            borderColor: '#CCCCCC',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#6100ff',
          },
        },
        underline: {
          '&:before': {
            borderBottom: '1px solid #999999',
          },
          '&:hover:before': {
            borderBottom: '1px solid #CCCCCC',
          },
          '&:after': {
            borderBottom: '2px solid #6100ff',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '1px solid #999999',
          },
          '&:hover:before': {
            borderBottom: '1px solid #CCCCCC',
          },
          '&:after': {
            borderBottom: '2px solid #6100ff',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: '#FFFFFF', // Chevron blanc par défaut
          '&:hover': {
            color: '#CCCCCC', // Chevron en hover
          },
          '&.Mui-focused': {
            color: '#6100ff', // Chevron quand focus
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#4c4c4c',
          },
          '&.Mui-selected': {
            backgroundColor: '#3a0099',
            '&:hover': {
              backgroundColor: '#3a0099',
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          color: '#CCCCCC',
          '&:hover': {
            color: '#ffffff',
          },
        },
        sizeLarge: {
          color: '#CCCCCC',
          '&:hover': {
            color: '#ffffff',
          },
        },
        sizeMedium: {
          color: '#CCCCCC',
          '&:hover': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        button: {
          color: '#CCCCCC',
          '&:hover': {
            color: '#ffffff',
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        switchViewButton: {
          color: '#CCCCCC',
          '&:hover': {
            color: '#ffffff',
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#6100ff',
      light: '#8700ff',
      dark: '#3a0099',
    },
    secondary: {
      main: '#E7901C',
      light: '#ffa93a',
      dark: 'cc7606',
    },
    background: {
      default: '#141414',
      paper: '#262626',
      // paper: '#1e1e1e',
    },
    text: {
      primary: '#F2F2F2',
      secondary: '#CCCCCC',
      disabled: '#7C7C7C',
    },
    action: {
      disabled: '#666666',
      disabledBackground: 'black',
    },
    uncolored: createColor('#4c4c4c'),
  },
  borderRadius: 3,
  boxShadow: `0px 5px 10px ${alpha('#000', 0.3)}`,
  elevation: 10,
  icons,
});
