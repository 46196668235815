import React from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SmsIcon from '@mui/icons-material/Sms';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

function ShareMenu() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { t } = useTranslation('cta');
  const { pathname } = useLocation();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  // Fonction pour copier l'URL
  const handleCopyLink = () => {
    let incentive = '';

    if (pathname === '/spots') {
      incentive = "Regarde ce Spot que j'ai trouvé sur Festayre ! \n";
    } else if (pathname === '/events') {
      incentive = "Regarde cet Event que j'ai trouvé sur Festayre ! \n";
    }

    navigator.clipboard.writeText(incentive + window.location.href);
    toast.success(t('Link copied to clipboard', { ns: 'notification' }));
    handleClose();
  };

  // Fonction pour générer le lien de partage selon l'application
  const shareLink = (platform: string) => {
    let incentive = '';

    if (pathname === '/spots') {
      incentive = "Regarde ce Spot que j'ai trouvé sur Festayre ! \n";
    } else if (pathname === '/events') {
      incentive = "Regarde cet Event que j'ai trouvé sur Festayre ! \n";
    }

    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent(incentive);

    let link = '';

    switch (platform) {
      case 'whatsapp':
        link = `https://wa.me/?text=${text} ${url}`;
        break;
      case 'sms':
        // 'sms:' avec `&body` est recommandé pour iOS et `?body` pour Android
        link = /iPhone|iPad|iPod/i.test(navigator.userAgent)
          ? `sms:&body=${text} ${url}`
          : `sms:?body=${text} ${url}`;
        break;
    }

    window.open(link, '_blank');
    handleClose();
  };

  return (
    <>
      <IconButton onClick={handleOpen} size="small" sx={{ p: 0, m: 0 }}>
        <theme.icons.share fontSize={isMobile ? 'small' : 'medium'} />
      </IconButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Stack direction="row" spacing={2} p={2} justifyContent="center">
          <Button onClick={handleCopyLink} size="small" color="inherit">
            <Stack alignItems="center" spacing={1}>
              <ContentCopyIcon fontSize={'medium'} />
              <Typography variant="caption" fontSize={{ xs: '.8rem', sm: '1.rem' }}>
                {t('link')}
              </Typography>
            </Stack>
          </Button>

          <Button onClick={() => shareLink('sms')} size="small" color="inherit">
            <Stack alignItems="center" spacing={1}>
              <SmsIcon fontSize={'medium'} />
              <Typography variant="caption" fontSize={{ xs: '.8rem', sm: '1.rem' }}>
                {t('message')}
              </Typography>
            </Stack>
          </Button>

          <Button onClick={() => shareLink('whatsapp')} size="small" color="inherit">
            <Stack alignItems="center" spacing={1}>
              <WhatsAppIcon fontSize={'medium'} />
              <Typography variant="caption" fontSize={{ xs: '.8rem', sm: '1.rem' }}>
                {t('whatsapp')}
              </Typography>
            </Stack>
          </Button>
        </Stack>
      </Popover>
    </>
  );
}

export default ShareMenu;
