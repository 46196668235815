import * as React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';

import { GetSpotDetailedQuery } from '@graphql';
import { AvatarCustom } from '@components';
import { truncateText } from '@utils';

interface RexProps {
  spot: GetSpotDetailedQuery['spot'];
}

function Rex(props: RexProps) {
  const { id, name, feedbacks } = props.spot;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack spacing={5} p={3}>
      <Typography variant="h4">REX</Typography>
      <Typography variant="body2">
        Retrouve tous les retours d'expériences des festayres qui ont déjà été à {name}
      </Typography>
      {feedbacks.map((feedback) => (
        <Stack key={feedback.id} spacing={4}>
          <Divider sx={{ backgroundColor: theme.palette.grey[700] }} />
          <Stack spacing={2}>
            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <AvatarCustom
                  ressource={feedback.author.avatar?.url || null}
                  id={feedback.author.id}
                  type="account"
                  sx={{
                    width: 30,
                    height: 30,
                  }}
                />
                <Stack>
                  <Typography variant="subtitle2" color="text.secondary" fontWeight="bold">
                    @{truncateText(feedback.author.slug, 20)}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1.1} alignItems="flex-end">
                <Rating
                  name="read-only"
                  size={isMobile ? 'small' : 'medium'}
                  value={
                    feedback.rate !== null && feedback.rate !== undefined ? feedback.rate * (5 - 1) + 1 : 0
                  }
                  readOnly
                />
                <Typography variant="body2" fontSize={{ xs: '.7rem', md: '1rem' }} fontWeight="bold">
                  {feedback.rate !== null && feedback.rate !== undefined ? feedback.rate * (5 - 1) + 1 : '-'}{' '}
                  / 5
                </Typography>
              </Stack>
            </Stack>
            <Typography
              variant="body2"
              color={feedback.text ? 'text.primary' : 'text.secondary'}
              p={1}
              sx={{
                backgroundColor: theme.palette.grey[800],
                borderRadius: theme.borderRadius,
                fontStyle: feedback.text ? 'normal' : 'italic',
              }}
            >
              {feedback.text || 'Pas de commentaire'}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Publié le {moment(feedback.createdAt).format('DD/MM/YYYY')}
            </Typography>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}

export default React.memo(Rex);
